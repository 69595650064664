import Api from './index'
import { store } from '@/store'


export default {
    async braintreeToken() {
        return Api().get(`braintree/client_token`, {
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async cancelSubscription() {
        return Api().post(`me/cancelSubscription`, {}, {
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async subscriptions() {
        return Api().get(`subscriptions`, {
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async subscribe(type, nonce) {
        const data = {
            braintreeNonce: nonce
        }
        return Api().post(`subscriptions/${type}/subscribe`, data, {
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    }
}