import Api from './index'

export default {
  async login(credentials) {
    return Api().post('users/login', credentials)
  },
  async register(credentials) {
    return Api().post('users', credentials)
  },
  async requestResetPassword(credentials) {
    return Api().post('users/requestPasswordReset', credentials)
  },
  async validatePasswordResetCode(credentials) {
    return Api().post('users/validatePasswordResetCode', credentials)
  },
  async resetPassword(credentials) {
    return Api().post('users/resetPassword', credentials)
  },
}