<template>
  <f7-page>
    <navbar :title="'Polityka prywatności'"></navbar>

    <f7-block class="text-align-center">
      <img style="height: 150px;" src="static/icons/policy.svg" alt />
    </f7-block>

    <f7-block>
      <h3 class="text-align-center">POLITYKA PRYWATNOŚCI</h3>
      <h4 class="text-align-center">§ 1 Postanowienia ogólne</h4>
      <p>1. Niniejsza Polityka Prywatności (zwana dalej „Polityką Prywatności”) określa sposób zbierania, przetwarzania i przechowywania danych osobowych koniecznych do realizacji usług świadczonych za pośrednictwem aplikacji mobilnej (zwanej dalej „Aplikacją”) przez Stowarzyszenie TRENING-PIŁKARSKI.PL.</p>
      <p>2. Użytkownik przyjmuje do wiadomości, że Administratorem danych osobowych jest Stowarzyszenie TRENING-PIŁKARSKI.PL, Krzywosądów 2, 63-322 Gołuchów, NIP: 6080112157, KRS: 0000670347, (zwane dalej „Administratorem”).</p>
      <p>3. Użytkownikiem jest każda osoba fizyczna korzystająca z usług świadczonych za pośrednictwem Aplikacji.</p>
      <p>4. Użytkownik przyjmuje do wiadomości, że udostępnianie przez niego danych osobowych jest dobrowolne. Udostępnianie Administratorowi danych osobowych przez Użytkownika nastąpi po zaakceptowaniu Polityki Prywatności i Regulaminu podczas rejestracji w Aplikacji.</p>
      <p>5. Dane osobowe, udostępniane przez Użytkownika, mogą być wykorzystywane tylko w przypadku powiadamiania o zmianie Regulaminu lub Polityki Prywatności.</p>
      <p>6. Niniejszym Użytkownik akceptuje zasady zawarte w Polityce Prywatności i w Regulaminie.</p>
      <h4 class="text-align-center">§ 2 Dane zbierane automatycznie</h4>
      <p>1. Administrator nie zbiera bez zgody Użytkownika danych osobowych, a wyłącznie dane nieposiadające takiego przymiotu, w szczególności dane demograficzne i dane dotyczące użytkowania Aplikacji. Zbieranie danych opisanych w zdaniu poprzednim odbywa się automatycznie (zwane dalej „dane zbierane automatycznie”).</p>
      <p>2. Dane zbierane automatycznie nie umożliwiają jednoznacznej identyfikacji Użytkownika</p>
      <p>3 .Dane zbierane automatycznie mogą służyć Administratorowi do poprawy jakości świadczonych usług, w szczególności w przypadku wystąpienia błędu Aplikacji. W sytuacji opisanej powyżej, dane zbierane automatycznie będą dotyczyły błędu Aplikacji, w tym stanu urządzenia mobilnego Użytkownika w chwili wystąpienia błędu, identyfikacji urządzenia mobilnego Użytkownika, fizycznej lokalizacji urządzenia mobilnego Użytkownika w chwili wystąpienia błędu</p>
      <p>4. Nie ma możliwości zmiany bądź usunięcia danych zbieranych automatycznie.</p>
      <h4 class="text-align-center">§ 3 Dane zbierane w celu nawiązania kontaktu</h4>
      <p>1. W przypadkach kontaktowania się Użytkownika z Administratorem, określonych w
Regulaminie, Administrator będzie wymagał podania przez Użytkownika: adresu
e-mail, (zwane dalej: „dane zbierane w celu nawiązania kontaktu”).</p>
<p>2. Podanie przez Użytkownika danych zbieranych w celu nawiązania kontaktu jest
dobrowolne, jednakże stanowić będzie wyłączną podstawę nawiązania kontaktu
zwrotnego Administratora z Użytkownikiem oraz umożliwi Administratorowi
weryfikację Użytkownika.</p>
<p>3.Dane zbierane w celu nawiązania kontaktu będą wykorzystywane wyłącznie w celu
umożliwienia poprawnej, pełnej i sprawnej komunikacji pomiędzy Administratorem a
Użytkownikiem.</p>
      <h4 class="text-align-center">§ 4 Zbieranie danych osobowych</h4>

<p>1. W trakcie rejestracji w Aplikacji i korzystania z Aplikacji, Administrator może
domagać się podania przez Użytkownika danych osobowych, w celu realizacji usług
świadczonych przez Administratora za pośrednictwem Aplikacji.</p>
<p>2. Dane osobowe Użytkownika zbierane w sposób określony w ust. 1 powyżej obejmują
m.in.: adres e-mail, informacje o koncie (nazwa Użytkownika, hasło, indywidualne ID
Użytkownika).</p>
      <h4 class="text-align-center">§ 5 Przetwarzanie danych osobowych</h4>

<p style="margin-bottom: 0px;">1. Dane osobowe przetwarzane będą:</p>
<p style="margin-bottom: 0px; margin-top: 0px">a) w celu rejestracji konta oraz świadczenia usługi subskrypcji
(art. 6 ust. 1 lit. b RODO w związku z realizacją umowy),</p>
<p style="margin-bottom: 0px; margin-top: 0px">b) w celu prawidłowego wyświetlania Aplikacji (pliki cookies),</p>
<p style="margin-bottom: 0px; margin-top: 0px">c) w celu monitorowania ruchu (logi),</p>
<p style="margin-top: 0px">d) w celu dochodzenia praw lub ochrony roszczeń
(art. 6 ust. 1 lit f w związku z ważnym interesem administratora)</p>
<p>2. Przez okres świadczenia usługi oraz zabezpieczenia ewentualnych roszczeń tj.
Pełne 5 lat podatkowych licząc od dnia zakończenia subskrypcji.</p>
<p>3. Posiada Pani/Pan prawo dostępu do treści danych, sprostowania, usunięcia,
sprzeciwu, przenoszenia danych i żądania ograniczenia przetwarzania, a także
prawo wniesienia skargi Prezesa Urzędu Ochrony Danych Osobowych.</p>
<p>4. Pani/a dane osobowe będą udostępniane firmie świadczącej usługi hostingu
(https://www.mydevil.net/), firmie świadczącej usługi projektowania oraz tworzenia
Aplikacji (PKstudio), a także operatorowi płatności elektronicznej
https://www.braintreepayments.com</p>
<p>5. Dane podlegają profilowaniu w zakresie wykorzystania narzędzi Google Ads, w
związku z czym przysługuje Państwu prawo do wniesienie sprzeciwu wobec
profilowania</p>
<p>6. Dane w związku z realizacją usługi płatności mogą być przetwarzane w krajach
trzecich (USA) przez operatora BrainTree Payments informację na temat klauzul
umownych oraz stosowanych mechanizmach zabezpieczeń dostępne są pod
adresem
https://www.braintreepayments.com/assets/Braintree-PSA-Model-Clauses-March201
8.pdf</p>
      <h4 class="text-align-center">§ 6 Prawa i obowiązki Administratora</h4>

<p>1. Jeśli wybierzesz bramę płatności Braintree, aby dokończyć zakup, Stowarzyszenie
TRENING-PIŁKARSKI.PL, przechowuje dane Twojej karty kredytowej. Jest
szyfrowany zgodnie ze standardem bezpieczeństwa danych kart płatniczych
(PCI-DSS). Twoje dane transakcji zakupu są przechowywane tylko tak długo, jak jest
to konieczne do zakończenia transakcji zakupu. Po zakończeniu informacje o
transakcji zakupu zostaną usunięte.</p>
<p>2. Wszystkie transakcje wykonywane przez zewnętrznych usługodawców płatności, nie
są przez nas przechowywane ani przetwarzane.</p>
<p>3. Wszystkie bezpośrednie płatności realizowane przez zewnętrznych usługodawców
dostosowane są do PCI-DSS, zarządzanym przez PCI Security Standards Council,
obsługujący karty Visy, MasterCard, American Express oraz Discover.</p>
<p>4. Wymagania PCI-DSS pomagają zapewnić bezpieczeństwo danych transakcji
płatniczych dokonywanych na UserEngage oraz powiązanych usługodawców
zewnętrznych.</p>
      <h4 class="text-align-center">§ 7 Prawa i obowiązki Administratora</h4>

<p>1. Administrator zobowiązuje się przetwarzać dane osobowe Użytkownika z
zachowaniem wymogów Ustawy z dnia 29 sierpnia 1997 roku o ochronie danych
osobowych oraz Ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą
elektroniczną.</p>
<p>2. Administrator gwarantuje zapewnienie odpowiednich środków technicznych i
organizacyjnych zapewniających bezpieczeństwo przetwarzanych danych
osobowych, w szczególności uniemożliwiających dostęp do nich nieuprawnionym
osobom trzecim, lub ich przetwarzania z naruszeniem przepisów powszechnie
obowiązującego prawa, zapobiegających utracie danych osobowych, ich
uszkodzeniu lub zniszczeniu</p>
<p>3. Dane osobowe Użytkownika będą przechowywane tak długo, jak będzie to
konieczne do realizacji przez Administratora usług świadczonych za pośrednictwem
Aplikacji.</p>
<p>4. Administrator ma prawo udostępniania danych osobowych Użytkownika: spółkom
zależnym; stronom trzecim w przypadku sprzedaży im całości lub części swoich
udziałów, lub w przypadku fuzji Administratora ze stroną trzecią, lub nabycia
udziałów w Spółce Administratora przez stronę trzecią; innym podmiotom trzecim,
które zaakceptowały Politykę Prywatności, o ile Administrator zawarł z nim umowę
konieczną do realizacji przez Administratora usług świadczonych za pośrednictwem
Aplikacji; właściwym organom, które zgłoszą konieczność udostępnienia danych
osobowych w oparciu o odpowiednie podstawy powszechnie obowiązującego prawa.</p>
      <h4 class="text-align-center">§ 8 Prawa i obowiązki Użytkownika</h4>

<p>1. Użytkownik ma prawo dostępu do swoich danych osobowych za pośrednictwem
Aplikacji.</p>
<p>2. Użytkownik może w każdej chwili dokonać modyfikacji, zmiany, uzupełnienia lub
usunięcia udostępnionych danych osobowych, za pośrednictwem narzędzi
dostępnych w Aplikacji.</p>
<p>3. W przypadku trwałego usunięcia przez Użytkownika danych osobowych,
koniecznych do realizacji przez Administratora usług świadczonych za
pośrednictwem Aplikacji, Użytkownik utraci możliwość korzystania z tych usług.</p>
<p>4. Administrator zastrzega sobie prawo wprowadzenia zmian w Polityce Prywatności, o
czym poinformuje Użytkownika za pośrednictwem Aplikacji. Jeżeli Użytkownik nie
wyrazi zgody na wprowadzone zmiany, zobowiązany jest trwale usunąć Aplikację ze
swojego urządzenia mobilnego.</p>
    </f7-block>

    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>

<script>
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
    ToolbarPlans,
    ToolbarMenu
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'more');
  }
};
</script>