<template>
  <f7-page @page:beforeremove="onPageBeforeRemove" @page:beforeout="onPageBeforeOut">
    <navbar :title="'Treningi'"></navbar>

    <toolbar-plans/>

    <loader v-if="loader"></loader>

    <div v-else>
      <div class="page-plan-training" style="height: 100%;">
        <f7-block style="margin-bottom: 20px">
          <f7-card class="plan-card">
            <f7-card-header class="no-border" valign="center">
              <img v-if="planTraining.attributes.thumbnail !== null" :src="`${host}${planTraining.attributes.thumbnail.path}`" alt />
              <div class="content">
                <div>
                  <p class="title">{{planTraining.attributes.title}}</p>
                  <p class="extra">
                    <span>Kategoria:</span>
                    {{categoryPlan.attributes.name}}
                  </p>
                  <p class="extra">
                    <span>Czas całkowity:</span>
                    {{secToTime(planTraining.attributes.totalTime)}}
                  </p>
                  <!-- <p class="extra">
                      <span>Tagi:</span>
                      <f7-chip v-for="tag in planTraining.attributes.tags" :key="tag.id" outline :text="tag.name"></f7-chip>
                    </p> -->
                </div>
              </div>
            </f7-card-header>

            <f7-card-content>
              <p class="description">{{planTraining.attributes.description}}</p>
            </f7-card-content>
          </f7-card>
        </f7-block>
        <f7-list
          v-if="this.planTraining.attributes.modules.length > 0"
          accordion-list
          inset
          class="custom-accordion"
          style="margin-bottom: 90px"
        >
          <f7-list-item
            accordion-item
            accordion-item-opened
            v-for="part in planTraining.attributes.modules"
            :key="part.id"
            :title="`Moduł: ${part.title} (Rundy: ${part.rounds})`"
          >
            <f7-accordion-content>
              <f7-list>
                <f7-list-item
                  v-for="exercise in part.exercises"
                  :key="exercise.id"
                  sheet-open=".demo-sheet-swipe-to-close"
                  @click="openSheetOpened(exercise.exercise.data.attributes._id)"
                >
                  <div class="wrapper">
                    <img v-if="exercise.exercise.data.attributes.cover !== null"  :src="`${host}${exercise.exercise.data.attributes.cover.path}`">
                    <div class="title">{{ exercise.exercise.data.attributes.title }}</div>
                  </div>
                  <div>
                    <span class="time">Czas: {{secToTime(exercise.totalTime)}}</span>
                  </div>
                </f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
    </div>
    <f7-toolbar v-if="!loader" position="bottom" class="start-toolbar">
      <f7-link class="w-100" @click="goPlayer()">ROZPOCZNIJ TRENING</f7-link>
    </f7-toolbar>
    
    <toolbar-menu />
    <f7-sheet 
        class="sheet" 
        :opened="sheetOpened" 
        @sheet:closed="sheetOpened = false" 
        style="height:auto; --f7-sheet-bg-color: #fff;"
        swipe-to-close
        backdrop
      >
      <f7-page-content>
        
        <loader v-if="loaderSheet"></loader>
        
        <div v-else>
          <div class="title">
            {{exerciseSheet.title}}
          </div>
          <div class="description">
            {{exerciseSheet.description}}
          </div>
          <div class="wrapperMedia" v-if="exerciseSheet.videos">
            <div class="after">
              <img height="50px" src="static/icons/video.svg">
              <p>Ładowanie filmu</p>
            </div>
            <div class="embed-container">
              <div class="wrapper-loader-vimeo wrapper-loader-vimeo-plans">
                <loader v-if="loaderVimeo"></loader>
              </div>

              <vueVimeoPlayer v-if="exerciseSheet.videos.length > 0"
                ref="player"
                :videoUrl="exerciseSheet.videos[0].src"
                :videoId="exerciseSheet.videos[0].src"
                :options="optionsVimeo"
                :loop="true"
                :controls="false"
                @ready="onReady"
                @play="onPlay"
                :player-height="400"
                :player-width="800"
              ></vueVimeoPlayer>
            </div>
          </div>
        </div>
      </f7-page-content>
    </f7-sheet>
  </f7-page>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import ToolbarMenu from '@/components/ToolbarMenu'
import WorkoutsService from "@/services/WorkoutsService";
import ToolbarPlans from "@/components/ToolbarPlans";
import Navbar from "@/components/Navbar";
import PlayerPlanTraining from "@/views/Pages/PlansTraining/PlayerPlanTraining";
import ExercisesService from "@/services/ExercisesService";
import Loader from "@/components/Loader";
import { secToTime } from "@/utils/common";

export default {
  props: {
    idCategory: {
      type: String,
      required: true
    },
    idPlan: {
      type: String,
      required: true
    }
  },
  components: {
    Navbar,
    PlayerPlanTraining,
    Loader,
    ToolbarPlans,
    ToolbarMenu,
    vueVimeoPlayer,
  },
  data: () => {
    return {
      optionsVimeo: {
        title: false,
        byline: false,
        portrait: false,
        sidedock: false,
        controls: false,
      },
      planTraining: {
        attributes: {
          thumbnail: {},
          modules: []
        }
      },
      isPlayer: false,
      loader: false,
      categoryPlan: {
        attributes: {
          name: ""
        }
      },
      playerReady: false,
      exerciseSheet: {},
      loaderSheet: false,
      sheetOpened: false,
      loaderVimeo: true
    };
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'plans-training');
    this.fetchCategoryWorkout(this.idCategory);
    this.fetchWorkout(this.idPlan);
  },
  methods: {
    async fetchCategoryWorkout(id) {
      try {
        const response = await WorkoutsService.categoryWorkout(id);
        this.categoryPlan = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWorkout(id) {
      this.loader = true;
      try {
        const response = await WorkoutsService.planWorkout(id);
        this.planTraining = response.data.data;
        this.loader = false;
      } catch (error) {
        if (error.response.status == 403 || error.response.status == 401) {
          this.$f7.toast
          .create({
              text: "Brak uprawnień do wyświetlenia ćwiczenia",
              position: "bottom",
              closeTimeout: 3000
          })
          .open();
          this.$f7router.back();
        }
        console.log(error);
      }
    },
    async openSheetOpened(idExercise) {
      this.sheetOpened = true;
      this.loaderSheet = true;
      try {
        const response = await ExercisesService.exercise(idExercise);
        this.exerciseSheet = response.data.data.attributes;
        this.loaderSheet = false;
      } catch (error) {
        if (error.response.status == 403 || error.response.status == 401) {
            this.$f7.toast
            .create({
                text: "Brak uprawnień do wyświetlenia ćwiczenia",
                position: "bottom",
                closeTimeout: 5000
            })
            .open();
        }
        console.log(error);
      }
    },
    goPlayer() {
      this.$f7router.navigate(`/categories-plans-training/${this.idCategory}/plan/${this.idPlan}/player`, {
        props: {
          planTraining: this.planTraining,
        }
      })
    },
    onPlay() {
      this.loaderVimeo = false
    },
    onReady() {
      this.playerReady = true
      
      this.$refs.player.play();
    },
    secToTime(duration) {
      return secToTime(duration);
    },
    onPageBeforeOut() {
      const self = this;
      self.$f7.sheet.close();
    },
    onPageBeforeRemove() {
      const self = this;
      if (self.sheet) self.sheet.destroy();
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/plansTraining.scss";
@import "@/assets/scss/pages/video.scss";
@import "@/assets/scss/sheet.scss";
</style>