<template>
  <f7-page>
    <navbar :title="'Regulamin'"></navbar>

    <f7-block class="text-align-center">
      <img style="height: 150px;" src="static/icons/regulations.svg" alt />
    </f7-block>

<f7-block>
      <h3 class="text-align-center">Regulamin aplikacji mobilnej "Pro Training - Trening Piłkarski"</h3>
      <p class="text-align-center">(stan: lipiec 2020)</p>
      <h4 class="text-align-center">§ 1 Informacje ogólne</h4>
      <p>1.  Niniejszy regulamin (dalej „Regulamin”) reguluje korzystanie z aplikacji mobilnej Pro Training - Trening Piłkarski (dalej „Usługa”).</p>
      <p>2. Usługodawcą jest Stowarzyszenie TRENING-PIŁKARSKI.PL (dalej
„Stowarzyszenie”) Krzywosądów 2, 63-322 Gołuchów, KRS: 0000670347, NIP:
6080112157, REGON: 36687586400000.</p>
      <p>3. Zasady korzystania z Usługi zostały opisane w niniejszym dokumencie, dostępnym
na stronie internetowej www.trening-pilkarski.pl, która umożliwia pozyskanie,
odtwarzanie i utrwalanie jego treści. Przed pobraniem i zainstalowaniem Usługi
każdy konsument zobowiązany jest do zapoznania się z treścią Regulaminu.</p>
      <h4 class="text-align-center">§ 2 Rodzaj i zakres Usługi</h4>

      <p>1. Usługa jest skierowana do konsumentów (dalej „Użytkownicy”) chcących doskonalić
swoje indywidualne umiejętności piłkarskie samodzielnie przy wykorzystaniu aplikacji
mobilnej. Celem Usługi jest dostarczanie Użytkownikom dopasowanych do ich
potrzeb planów treningu piłkarskiego, które mają na celu poprawę indywidualnych
umiejętności piłkarskich Użytkownika. W szczególności Usługa ma na celu
dostarczanie planów treningu piłkarskiego technicznego pozwalających na poprawę
panowania piłki i jej kontroli podczas treningu lub gry.</p>
      <p style="margin-bottom: 0px;">2. Usługa umożliwia Użytkownikom:</p>
      <p style="margin-bottom: 0px; margin-top: 0px;">a) Korzystanie z umieszczonych w Usłudze planów treningowych
przeznaczonych do samodzielnego przeprowadzenia treningu przez
Użytkownika,</p>
      <p style="margin-bottom: 0px; margin-top: 0px;">b) Uzyskanie cyfrowej wersji dowodu zakupu,</p>
      <p style="margin-top: 0px;">c) Dokonanie płatności za subskrypcję Usługi za pomocą Braintree Payments.</p>
      <h4 class="text-align-center">§ 3 Warunki korzystania z Usługi</h4>

      <p>1. Korzystanie z usługi jest możliwe wyłącznie przez klientów indywidualnych.
Wykorzystywanie Usługi w celach komercyjnych jest niedopuszczalne. Pobranie
Usługi przez Użytkownika jest bezpłatne. Uzyskanie dostępu do planów
treningowych wymaga opłaty subskrypcyjnej</p>
      <p>2. Użytkownik w celu skorzystania z Usługi musi pobrać aplikację mobilną na swoje
urządzenie przenośne. W celu pobrania, zainstalowania i korzystania z Usługi na
urządzeniu mobilnym Użytkownik powinien posiadać dostęp do Internetu. Minimalne
wymagania sprzętowe i rodzaje urządzeń przenośnych, na które może być pobrana
aplikacja zawierająca Usługę zostały opisane w punkcie 4 Regulaminu.</p>
      <p>3. Po pobraniu i zainstalowaniu aplikacji mobilnej Użytkownik w celu skorzystania z
usługi musi założyć konto użytkownika (dalej „Konto”).</p>
      <p style="margin-bottom: 0px;">4. W ramach rejestracji i założenia Konta wymagane jest od Użytkownika udostępnienie
następujących danych:</p>
      <p style="margin-bottom: 0px; margin-top: 0px;">a) Imię i nazwisko,</p>
      <p style="margin-top: 0px;">b) Adres e-mail.</p>
      <p>5. Wszelkie podawane przez Użytkownika dane nie mogą być przyporządkowane do
innej osoby. Zabrania się również podawania fałszywych danych, w szczególności
tych nienależących do Użytkowników. Obowiązkiem użytkownika jest
przechowywanie swoich danych dostępowych w bezpieczny sposób, który
uniemożliwia dostęp do Konta osobom trzecim.</p>
      <p>6. Każdy kontakt ze Stowarzyszeniem TRENING-PIŁKARSKI.PL za pośrednictwem
założonego Konta zostanie przypisanym danemu Użytkownikowi. Zasada ta
obowiązuje również, jeżeli z winy Użytkownika osoby trzecie uzyskają dostęp do jego
Konta. Użytkownik jest zobowiązany do niezwłocznego poinformowania
Stowarzyszenia TRENING-PIŁKARSKI.PL o każdym niepożądanym skorzystaniu z
należącego do niego Konta oraz do zmiany swoich danych dostępu, jeżeli dostęp do
Konta uzyskały osoby trzecie.</p>
      <p>7. W wyniku rejestracji i założenia Konta każdy Użytkownik otrzymuje dostęp do Usługi
poprzez logowanie unikalnym e-mailem oraz hasłem.</p>
      <p>8. Usługa daje możliwość skorzystania z oferowanej części darmowej bez konieczności
opłacania subskrypcji. Jeżeli Użytkownik chce skorzystać z dostępu do płatnych
planów treningowych wymagana jest opłata subskrypcyjna półroczna lub roczna.</p>
      <p>9. Opłata subskrypcyjna ulega automatycznemu przedłużeniu na kolejny okres
rozliczeniowy, chyba że Użytkownik zrezygnuje z subskrypcji usługi najpóźniej na
dzień przed odnowieniem subskrypcji.</p>
      <h4 class="text-align-center">§ 4 Warunki techniczne korzystania z Usługi</h4>

      <p>1. Korzystanie z Usługi wymaga od Użytkownika pobrania aplikacji mobilnej,
odpowiednio dla systemów operacyjnych urządzeń mobilnych, ze sklepów Google
Play, App Store poprzez wyszukanie aplikacji o nazwie "Pro Training - Trening Piłkarski".</p>
      <p>2. Aplikację mobilną można pobrać na urządzenia przenośne posiadające dostęp do
Internetu i pracujące w oparciu o system operacyjny Android, iOS w szczególności
takie jak telefony komórkowe, smartfony, tablety.</p>
<p style="margin-bottom: 0px;">3. Korzystanie z Usługi wymaga w szczególności:</p>
      <p style="margin-bottom: 0px; margin-top: 0px;">a) Urządzenia końcowego z dostępem do sieci Internet oraz do działającej
przeglądarki internetowej,</p>
      <p style="margin-top: 0px;">b) Aktywnego konta poczty elektronicznej.</p>
      <p>4. Stowarzyszenie TRENING-PIŁKARSKI.PL stara się zapewnić, aby Usługa była
obsługiwana przez możliwie wiele typów urządzeń mobilnych. Ze względu na ciągle
zmieniającą się gamę produktów na rynku urządzeń mobilnych Stowarzyszenie
TRENING-PIŁKARSKI.PL nie może podać aktualnej listy urządzeń mobilnych, na
których możliwe jest korzystanie z Usługi.</p>
      <p>5. Instalacja aplikacji mobilnej i korzystanie z Usługi wymaga stałej transmisji danych z
urządzenia mobilnego Użytkownika. Wielkość i częstotliwość przesyłu danych zależą
od rodzaju i zakresu korzystania z Usługi. Koszty połączenia za transjisję danych
ponosi Użytkownik. Ich wysokość jest uzależniona od umowy zawartej między
Użytkownikiem, a jego operatorem sieci komórkowej.</p>
      <p>6. Korzystanie z Usługi wymaga również odpowiedniego stanu naładowania baterii oraz
jasności wyświetlacza niezbędnej do odtwarzania plików video znajdujących się w
aplikacji mobilnej.</p>
      <p>7. Stowarzyszenie TRENING-PIŁKARSKI.PL może publikować i oferować aktualizację
aplikacji mobilnej. Do prawidłowego działania Usługi konieczna jest jej aktualizacja.
Stowarzyszenie TRENING-PIŁKARSKI.PL nie gwarantuje poprawnego działania
Usługi jeśli Użytkownik nie pobrał i nie zainstalował na swoim urządzeniu
przenośnym udostępnionej przez Stowarzyszenie TRENING-PIŁKARSKI.PL
aktualizacji.</p>
      <h4 class="text-align-center">§ 5 Dostęp do Usługi przez Użytkownika oraz opłaty</h4>

<p style="margin-bottom: 0px;">1. Wyróżniamy dwa rodzaje dostępów do Aplikacji:</p>
      <p style="margin-bottom: 0px; margin-top: 0px;">a) Freemium - po założeniu konta bezpłatny dostęp do darmowej części
Aplikacji,</p>
      <p style="margin-top: 0px;">b) Premium - po założeniu konta i dokonaniu płatności subskrybcyjnej dostęp do
części Premium.</p>
<p style="margin-bottom: 0px;">2. Użytkownik ma do dyspozycji trzy okresy na jakie może dokonać subskrypcji Usługi:</p>
      <p style="margin-bottom: 0px; margin-top: 0px;">a) okres 1 miesięczny,</p>
      <p style="margin-bottom: 0px; margin-top: 0px;">a) okres 6 miesięczny,</p>
      <p style="margin-top: 0px;">b) okres 12 miesięczny.</p>
      <p>3. Płatności za subskrypcję Usługi obsługiwane są przez firmę Braintree Payments.</p>
      <p>Administratorem danych dotyczących płatności jest Braintree Payments, po otrzymaniu
danych transakcji obsługuje płatność oraz odpowiada za jej prawidłowy przebieg, zwracając
informację, czy transakcja przebiegła prawidłowo. Wszystkie szczegóły transakcji chroni i
zapewnia firma Braintree Payments.</p>
      <h4 class="text-align-center">§ 6 Ochrona danych osobowych</h4>

      <p>1. Ogólne regulacje dotyczące polityki i ochrony gromadzenia danych osobowych,
nieuregulowane niniejszym Regulaminem, zostały sprecyzowane w odpowiednim
dziale dostępnym na stronie internetowej
www.trening-pilkarski.pl/polityka-prywatnosci.</p>
      <p>2. Akceptacja warunków przetwarzania danych osobowych za pośrednictwem Usługi
następuje poprzez zaznaczenie odpowiedniego pola w aplikacji mobilnej "Pro Training - Trening Piłkarski".</p>
      <p>3. Akceptacja warunków przekazywania danych osobowych zgromadzonych za
pośrednictwem Usługi na rzecz podmiotów trzecich następuje poprzez zaznaczenie
odpowiedniego pola w aplikacji mobilnej "Pro Training - Trening Piłkarski"</p>
      <p style="margin-bottom: 0px;">4. Podczas korzystania z Usługi Stowarzyszenie TRENING-PIŁKARSKI.PL gromadzi o
Użytkownikach takie dane jak:</p>
<p style="margin-bottom: 0px; margin-top: 0px;">a) Imię i nazwisko</p>
<p style="margin-bottom: 0px; margin-top: 0px;">b) Adres e-mail</p>
<p style="margin-bottom: 0px; margin-top: 0px;">c) Wersja używanego systemu operacyjnego</p>
<p style="margin-bottom: 0px; margin-top: 0px;">d) Numer używanego urządzenia mobilnego</p>
<p style="margin-bottom: 0px; margin-top: 0px;">e) Język systemowy</p>
<p style="margin-bottom: 0px; margin-top: 0px;">f) Używana wersja aplikacji</p>
<p style="margin-bottom: 0px; margin-top: 0px;">g) Rodzaj Konta użytkownika – darmowe lub płatne</p>
<p style="margin-top: 0px;">h) Typ subskrypcji – miesięczna, półroczna lub roczna</p>
      <p>5. Gromadzenie danych za pośrednictwem Usługi służy jak najlepszemu wywiązaniu
się przez Stowarzyszenie TRENING-PIŁKARSKI.PL ze wszelkich zobowiązań
nałożonych na nie przez niniejszy Regulamin oraz powszechnie obowiązujące
przepisy prawa.</p>
      <p>6. Administratorem danych osobowych jest Stowarzyszenie TRENING-PIŁKARSKI.PL
kontakt: e-mail: info@trening-pilkarski.pl</p>
      <p>7. Dane osobowe przetwarzane będą w celu rejestracji konta oraz świadczenia usługi
subskrypcji (art. 6 ust. 1 lit. b RODO w związku z realizacją umowy), a także w celu
dochodzenia praw lub ochrony roszczeń w związku z art. 6 ust. 1 lit f w związku z
ważnym interesem administratora) przez okres świadczenia usługi oraz
zabezpieczenia ewentualnych roszczeń tj. Pełne 5 lat podatkowych licząc od dnia
zakończenia subskrypcji.</p>
      <p>8. Posiada Pani/Pan prawo dostępu do treści danych, sprostowania, usunięcia,
sprzeciwu, przenoszenia danych i żądania ograniczenia przetwarzania, a także
prawo wniesienia skargi Prezesa Urzędu Ochrony Danych Osobowych.</p>
      <p>9. Pani dane osobowe będą udostępniane firmie świadczącej usługi hostingu
(https://www.mydevil.net/), firmie świadczącej usługi projektowania oraz tworzenia
systemu PKstudio a także operatorowi płatności elektronicznej
https://www.braintreepayments.com</p>
      <p>10. Dane w związku z realizacją usługi płatności mogą być przetwarzane w krajach
trzecich (USA) przez operatora BrainTree Payments informację na temat klauzul
umownych oraz stosowanych mechanizmach zabezpieczeń dostępne są pod
adresem
https://www.braintreepayments.com/assets/Braintree-PSA-Model-Clauses-March201
8.pdf</p>
      <h4 class="text-align-center">§ 7 Zakończenie korzystania z Usługi i odpowiedzialność stron</h4>

      <p>1. Użytkownik w każdej chwili ma możliwość rezygnacji z przedłużenia Subskrypcji na
kolejny okres rozliczeniowy.</p>
      <p style="margin-bottom: 0px;">2. Stowarzyszenie TRENING-PIŁKARSKI.PL ma możliwość wypowiedzenia
świadczenia Usługi z ważnych przyczyn, w szczególności:</p>
<p style="margin-bottom: 0px; margin-top: 0px;">a) Korzystanie z Usługi w sposób sprzeczny z prawem</p>
<p style="margin-bottom: 0px; margin-top: 0px;">b) Korzystanie z Usługi w sposób sprzeczny z jej celem</p>
<p style="margin-top: 0px;">c) Podanie nieprawdziwych danych Użytkownika potrzebnych do założenia
Konta</p>
      <p style="margin-bottom: 0px;">3. Usługodawca nie ponosi odpowiedzialności w przypadku:</p>
<p style="margin-bottom: 0px; margin-top: 0px;">a) Czasowego braku dostępu do Usługi</p>
<p style="margin-bottom: 0px; margin-top: 0px;">b) Braku lub ograniczonego dostępu do Internetu</p>
<p style="margin-top: 0px;">c) Awarii urządzenie przenośnego Użytkownika</p>
      <h4 class="text-align-center">§ 8 Postępowanie reklamacyjne</h4>

      <p>1. Wszelkie uwagi i reklamacje dotyczące funkcjonowania aplikacji mobilnej PRO
SKILLS TRAINING należy zgłaszać za pośrednictwem adresu e-mail:
info@trening-pilkarski.pl.</p>
      <p>2. Uwagi dotyczące przetwarzania danych osobowych należy zgłaszać na adres e-mail:
info@trening-pilkarski.pl.</p>
      <p>3. Użytkownik może złożyć reklamację w terminie 14 dni, licząc od dnia, w którym miały
miejsce wady lub przerwy w świadczeniu usług drogą elektroniczną za
pośrednictwem Usługi. Reklamację złożoną po upływie terminu określonego w
zdaniu pierwszym, pozostawia się bez rozpoznania, o czym Stowarzyszenie
TRENING-PIŁKARSKI.PL niezwłocznie powiadamia Użytkownika za pośrednictwem
poczty elektronicznej na adres wskazany przez Użytkownika w ramach rejestracji lub
poprzez odpowiednią wiadomość przesłaną z użyciem Usługi. Nie uchybia to jednak
możliwości dochodzenia przez Użytkownika roszczeń wynikających z ogólnie
obowiązujących przepisów prawa.</p>
      <p>4. W reklamacji Użytkownik powinien podać co najmniej: swoje imię i nazwisko, adres
do korespondencji (może być to adres mailowy, jeśli Użytkownik wybiera taki sposób
porozumiewania się), rodzaj i datę wystąpienia przyczyn reklamacji oraz wszystkie
okoliczności uzasadniające złożenie reklamacji. W przypadku braku w treści
reklamacji danych umożliwiających właściwe rozpatrzenie reklamacji,
Stowarzyszenie TRENING-PIŁKARSKI.PL może zwrócić się do składającego
reklamację o uzupełnienie danych.</p>
      <p>5. Po rozpatrzeniu reklamacji, Stowarzyszenie TRENING-PIŁKARSKI.PL udzieli
Użytkownikowi odpowiedzi na reklamację w terminie 14 dni od dnia jej otrzymania.
Odpowiedź wysłana zostanie na adres do korespondencji Użytkownika lub adres
e-mail Użytkownika wskazany w treści reklamacji (a w razie jego niewskazania w
treści reklamacji – na adres e-mail przypisany do konta Użytkownika w aplikacji
mobilnej Pro Training - Trening Piłkarski), ewentualnie przekazana w inny sposób
uzgodniony indywidualnie z Użytkownikiem.</p>
      <h4 class="text-align-center">§ 9 Postanowienia końcowe</h4>

      <p style="margin-bottom: 0px;">1. Regulamin Usługi może ulec zmianie w przypadku:</p>
<p style="margin-bottom: 0px; margin-top: 0px;">a) zmiany obowiązujących przepisów prawa mających zastosowanie do Usługi,</p>
<p style="margin-bottom: 0px; margin-top: 0px;">b) zmiany funkcjonalności Usługi, z zastrzeżeniem że zmiany Regulaminu mają
na celu dostosowanie treści Regulaminu do jego funkcjonalności,</p>
<p style="margin-top: 0px;">c) zmiany w zakresie działalności prowadzonej przez Stowarzyszenie
TRENING-PIŁKARSKI.PL za pośrednictwem Usługi, w szczególności, w razie
wprowadzenia nowych usług świadczonych za pośrednictwem Usługi.</p>
      <p>2. O treści zmian Regulaminu każdy Użytkownik zostanie poinformowany za pomocą
stosownego powiadomienia w aplikacji mobilnej lub wiadomości e-mail.</p>
      <p>3. Poinformowanie o zmianie Regulaminu w sposób określony powyżej nastąpi nie
później niż na 14 dni kalendarzowych przed wejściem w życie zmienionego
Regulaminu. W przypadku, gdy Użytkownik zarejestrowany w Usłudze nie akceptuje
nowej treści Regulaminu, może wypowiedzieć łączącą go ze Stowarzyszeniem
TRENING-PIŁKARSKI.PL umowę o świadczenie usług, poprzez odinstalowanie z
urządzenia aplikacji mobilnej.</p>
      <p>4. W sprawach nieuregulowanych w Regulaminie mają zastosowanie przepisy prawa
polskiego, a zwłaszcza Kodeksu cywilnego oraz przepisy Ustawy z dnia 30 maja
2014 r. o prawach konsumenta (Dz. U. z 2014 r., poz. 827).</p>
      <p>5. Wszelkie spory powstałe pomiędzy Stowarzyszenie TRENING-PIŁKARSKI.PL, a
Użytkownikiem w związku z korzystaniem z Usługi oraz umowami o świadczenie
usług rozstrzygane będą w pierwszej kolejności polubownie, a następnie przez sąd
właściwy według powszechnie obowiązujących przepisów.</p>
</f7-block>


    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>

<script>
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    ToolbarPlans,
    ToolbarMenu,
    Navbar
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'more');
  }
};
</script>