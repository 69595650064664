<template>
  <f7-page>
    <f7-navbar class="custom-navbar">
      <f7-nav-left>
        <f7-link>
          <img class="logo" src="static/logo-trening-pilkarski.svg" style="max-width: 25px;" />
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Pro Training - Trening Piłkarski</f7-nav-title>
      <f7-nav-right>
        <f7-link v-if="!isAuthenticated" @click="onRedirectToLogin">
          <span>Zaloguj</span>
          <img height="20px" src="static/icons/login.svg" alt />
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block class="homePage">
      <f7-row>
        <f7-col width="100" tablet-width="100">
          <f7-card class="custom-card">
            <div>
              <img height="100px" src="static/logo-trening-pilkarski.svg" alt />
            </div>
          </f7-card>
        </f7-col>
      </f7-row>

      <div class="header">
        <img src="static/icons/plans.svg" alt="">
        <h2>Najnowsze ćwiczenia:</h2>
      </div>
      <loader v-if="loaderExercises"></loader>
      <f7-block v-else class="page-plan-training">
        <div v-if="listExercises.length > 0" class="scrolling-wrapper">
          <a
            v-for="exercise in listExercises"
            :key="exercise.id"
            v-show="showAccessItem(exercise.attributes.access)"
            :href="exercise.attributes.access ? `/categories-exercises/${exercise.relationships.category.data.id.split('/').pop()}/exercise/${exercise.attributes._id}` : '/plan-upgrade/'"
          >
            <f7-card class="plan-card">
              <f7-card-header class="no-border" valign="center">
                <img v-if="exercise.attributes.cover !== null" class="list" :src="`${host}${exercise.attributes.cover.path}`" alt />
                <div v-show="!exercise.attributes.access" class="access">
                  <img
                    src="static/icons/lock.svg"
                    alt="lock"
                  />
                </div>
              </f7-card-header>
            </f7-card>
            <p class="title">{{exercise.attributes.title}}</p>
          </a>
        </div>
        <div v-else>
          <f7-block class="text-align-center">
            <img style="height: 50px;" src="static/icons/bag.svg" alt />
          </f7-block>
          <f7-block class="text-align-center">Brak ćwiczeń w bazie.</f7-block>
        </div>
        <f7-button href="/categories-exercises/">Zobacz więcej</f7-button>
      </f7-block>

      <div class="header">
        <img src="static/icons/exercises.svg" alt="">
        <h2>Najnowsze treningi:</h2>
      </div>

      <loader v-if="loaderWorkouts"></loader>
      <f7-block v-else class="page-plan-training">
        <div v-if="listWorkouts.length > 0" class="scrolling-wrapper">
          <a
            v-for="plan in listWorkouts"
            :key="plan.id"
            v-show="showAccessItem(plan.attributes.access)"
            :href="plan.attributes.access ? `/categories-plans-training/${plan.relationships.category.data.id.split('/').pop()}/plan/${plan.attributes._id}` : '/plan-upgrade/'"
          >
            <f7-card class="plan-card">
              <f7-card-header class="no-border" valign="center">
                <img v-if="plan.attributes.thumbnail !== null" class="list" :src="`${host}${plan.attributes.thumbnail.path}`" alt />
                <div v-show="!plan.attributes.access" class="access">
                  <img
                    src="static/icons/lock.svg"
                    alt="lock"
                  />
                </div>
              </f7-card-header>
            </f7-card>
            <p class="title">{{plan.attributes.title}}</p>
          </a>
        </div>
        <div v-else>
          <f7-block class="text-align-center">
            <img style="height: 50px;" src="static/icons/bag.svg" alt />
          </f7-block>
          <f7-block class="text-align-center">Brak planów treningowych w bazie.</f7-block>
        </div>
        <f7-button href="/categories-plans-training/">Zobacz więcej</f7-button>
      </f7-block>
    </f7-block>

    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>

<script>
import ExercisesService from "@/services/ExercisesService";
import WorkoutsService from "@/services/WorkoutsService";
import Loader from "@/components/Loader";
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";

export default {
  components: {
    ToolbarPlans,
    ToolbarMenu,
    Loader
  },
  data: () => {
    return {
      listExercises: [],
      loaderExercises: false,
      listWorkouts: [],
      loaderWorkouts: false,
      countBackKeyDown: 0,
    };
  },
  mounted() {
    this.checkVerified(this.myToken)
    this.fetchLatestExercises(5);
    this.fetchLatestWorkouts(5);
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'home');
    this.countBackKeyDown = 0;
    document.addEventListener("deviceready", this.onDeviceReady, false);
  },
  methods: {
    showAccessItem(access) {
      if (this.getDeviceIos && !access) {
        return false
      } else {
        return true
      }
    },
    onDeviceReady() {
      document.addEventListener("backbutton", this.onBackKeyDown, false);
    },
    onBackKeyDown() {
      e.preventDefault();
      this.countBackKeyDown++;
      if (this.$f7router.url == '/home/' && this.countBackKeyDown > 1) {
        window.navigator.app.exitApp();
      }
    },
    async fetchLatestExercises(count) {
      this.loaderExercises = true;
      try {
        const response = await ExercisesService.latestExercises(count);
        this.listExercises = response.data.data;
        this.loaderExercises = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchLatestWorkouts(count) {
      this.loaderWorkouts = true;
      try {
        const response = await WorkoutsService.latestWorkouts(count);
        this.listWorkouts = response.data.data;
        this.loaderWorkouts = false;
      } catch (error) {
        console.log(error);
      }
    },
    onRedirectToLogin() {
      this.$f7router.navigate("/login/");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/home.scss";
@import "@/assets/scss/pages/all.scss";
@import "@/assets/scss/card.scss";
@import "@/assets/scss/navbar.scss";
@import "@/assets/scss/toolbar.scss";
</style>