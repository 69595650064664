import truncate from './truncate'
import formatDate from './formatDate'

export {
  truncate,
  formatDate
}

export default function (Vue) {
  Vue.filter('truncate', truncate)
  Vue.filter('formatDate', formatDate)
}