<template>
  <f7-page login-screen>
    <navbar :title="'Nie ma takiej strony'"></navbar>

    <f7-block class="text-align-center">
      <img style="height: 150px;" src="static/icons/404.svg" alt />
    </f7-block>
    <f7-block class="text-align-center">Nie ma takiej strony!</f7-block>
    <f7-block class="text-align-center">
      <f7-button href="/" fill>Strona główna</f7-button>
    </f7-block>

    <toolbar-menu />
  </f7-page>
</template>
<script>
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
    ToolbarPlans,
    ToolbarMenu
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", '');
  }
};
</script>