<template>
  <f7-page>
    <navbar :title="'Treningi'"></navbar>

    <loader v-if="loader"></loader>

    <f7-block v-else class="page-plan-training">
      <a v-for="(category) in categoryPlans" :key="category.attributes.id" :href="`/categories-plans-training/${category.attributes._id}`">
            <f7-card class="custom-card">
              <div>
                <p>{{category.attributes.name}}</p>
              </div>
              <div v-if="category.attributes.thumbnail !== null" class="before">
                <div class="image" :style="{backgroundImage: 'url(' + `${host}${category.attributes.thumbnail.path}` + ')'}"></div>
              </div>
          </f7-card>
      </a>
    </f7-block>

    <toolbar-plans />
    <toolbar-menu />

  </f7-page>
</template>

<script>
  import WorkoutsService from '@/services/WorkoutsService'
  import ToolbarPlans from '@/components/ToolbarPlans'
  import ToolbarMenu from '@/components/ToolbarMenu'
  import Loader from '@/components/Loader'
  import Navbar from "@/components/Navbar";

  export default {
    components: {
      Navbar,
      ToolbarPlans,
      ToolbarMenu,
      Loader
    },
    data: () => {
      return {
        categoryPlans: [],
        loader: false,
      };
    },
    mounted() {
      this.$store.dispatch("apptreningpilkarski/setActualPage", 'plans-training');
      this.checkVerified(this.myToken)
      this.fetchCategoryPlans();
    },
    methods: {
      async fetchCategoryPlans() {
        this.loader = true;
        try {
          const response = await WorkoutsService.categoryWorkouts()
          this.categoryPlans = response.data.data;
          this.loader = false;
        } catch (error) {
          console.log(error);
        }
      },
    }
  }
</script>

<style lang="scss">
@import '@/assets/scss/pages/plansTraining.scss';
</style>