import Api from './index'
import { store } from '@/store'


export default {
    async latestExercises(count) {
        return Api().get(`exercises`, {
            params: {
                itemsPerPage: count,
            },
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async listExercises(search, category, page) {
        return Api().get(`exercises`, {
            params: {
                title: search,
                'category.id': category,
                page: page
            },
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async exercise(idExercise) {
        return Api().get(`exercises/${idExercise}`, {
            params: {
                include: 'category'
            },
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async categoryExercise(id) {
        return Api().get(`/exercise_categories/${id}`, {
            headers: {
            'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async categoryExercises() {
        return Api().get(`/exercise_categories`, {
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    }
}