<template>
  <f7-toolbar v-if="!isPlans && !getDeviceIos" position="top" class="not-plan-toolbar">
    <f7-link class="w-100" href="/plan-upgrade/"><f7-icon f7="shopping_cart"></f7-icon> Kup dostęp</f7-link>
  </f7-toolbar>
</template>

<script>
import onbackbutton from '@/mixins/onBackButton.js';

export default {
  name: 'toolbar-plans',
  mixins: [onbackbutton],
}
</script>