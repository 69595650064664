import { mapGetters } from 'vuex'
import userServices from "@/services/UserService";
import jwt_decode from "jwt-decode";

export default {
  computed: {
    ...mapGetters({
      isAuthenticated: 'apptreningpilkarski/isAuthenticated',
      isPlans: 'apptreningpilkarski/isPlans',
      myUser: 'apptreningpilkarski/myUser',
      myToken: 'apptreningpilkarski/myToken',
      isVerified: 'apptreningpilkarski/isVerified',
      getAppSound: 'apptreningpilkarski/getAppSound',
      getActualPage: 'apptreningpilkarski/getActualPage',
      getDeviceIos: 'apptreningpilkarski/getDeviceIos'
    }),
    host() {
      if (process.env.NODE_ENV == 'development') {
        return 'https://api.trening-pilkarski.pl'
      }
      if (process.env.NODE_ENV == 'production') {
        return 'https://api.trening-pilkarski.pl'
      }
    }
  },
  methods: {
    async checkVerified(token) {
      if (this.isAuthenticated) {
        const id = jwt_decode(token).userId
        try {
          const responseUser = await userServices.user(id);
          this.$store.dispatch("apptreningpilkarski/setVerified", responseUser.data.data.attributes.verified);
          this.$store.dispatch("apptreningpilkarski/setPlans", responseUser.data.data.attributes.roles[0] == 'ROLE_SUBSCRIBER' ? true : false);
        } catch (error) {
          console.log(error);
        }
      }
    },
  }
}