<template>
  <f7-page login-screen>
    <f7-navbar title="Wybierz plan" back-link="Back"></f7-navbar>
    <div v-if="!isAuthenticated">
      <f7-block class="text-align-center">
        <img style="height: 150px;" src="static/icons/shield.svg" alt />
      </f7-block>
      <f7-block
        class="text-align-center"
      >Chcesz wykupić dostęp do płatnej części aplikacji, najpierw musisz się zalogować lub stworzyć konto.</f7-block>
      <f7-block class="text-align-center">
        <f7-row>
          <f7-col>
            <f7-button href="/login/" fill>Zaloguj się</f7-button>
          </f7-col>
          <f7-col>
            <f7-button href="/register/" fill>Rejestracja</f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
    <div v-else>
      <div v-if="getDeviceIos">
          <f7-block class="text-align-center">
            <img style="height: 150px;" src="static/icons/access.svg" alt />
          </f7-block>
          <f7-block>
          <f7-button
              href="https://trening-pilkarski.pl/app/"
                fill external target="_blank"
              >Przejdź na stronę Trening Piłkarski</f7-button>
          </f7-block>
      </div>
      <div v-else>

        <div v-if="isVerified" class="form-braintree">
          <div v-if="step == 'credit-card'">
          <f7-block class="text-align-center">
            <img style="height: 150px;" src="static/icons/access.svg" alt />
          </f7-block>
          <f7-list form>
            <div class="form-group group-box">
              <label>Twój plan</label>
              <loader v-if="loader"></loader>
              <div v-else class="row box">
                <div
                  v-for="type in subscriptions"
                  :key="type.id"
                  class="col-33 box-item"
                  :class="{'active': type.attributes._id == selectSubscription}"
                  @click="setPlan(type)"
                >
                  <h4>{{type.attributes.price}} PLN</h4>
                  <p>{{type.attributes.name}}</p>
                </div>
              </div>
            </div>
      
            <div id="dropin-container"></div>
            <f7-block class="text-align-center wrapper-form-button">
              <f7-button
                v-if="!loaderPay"
                fill
                @click.prevent="dropinRequestPaymentMethod"
                :disabled="loaderPay"
              ><span v-if="!loader">Zapłać {{amount}} PLN</span></f7-button>
              <f7-button v-else fill>
                <f7-preloader :size="20" color="white"></f7-preloader>
              </f7-button>
              <span>Twój plan będzie automatycznie odnawiany, ale będziesz mógł anulować subkskrypcję w dowolnym momencie.</span>
            </f7-block>
          </f7-list>
        </div>

        <div v-if="step == 'done'">
          <f7-block class="text-align-center" style="margin-top: -50px; margin-bottom: -75px;">
            <lottie
              :options="defaultOptionsDone"
              :height="300"
              :width="300"
              v-on:animCreated="handleAnimation"
            />
          </f7-block>
          <f7-block class="text-align-center">
            <h4>Płatność została przyjęta!</h4>
            <span>Od teraz możesz korzystać z płatnych planów treningowych i ćwiczeń :)</span>
          </f7-block>
          <f7-block class="text-align-center">
            <f7-button @click="onBackHome" fill>Strona główna</f7-button>
          </f7-block>
        </div>


<div v-if="step == 'cancel'">
          <f7-block class="text-align-center" style="margin-top: -50px; margin-bottom: -75px;">
            <lottie
              :options="defaultOptionsCancel"
              :height="300"
              :width="300"
              v-on:animCreated="handleAnimation"
            />
          </f7-block>
          <f7-block class="text-align-center">
            <h4>Płatność nie powiodła się :(</h4>
            <span>Powodem może być nie wystarczająca ilość pieniędzy na koncie, musisz skontaktować się z supportem w celu wyjaśnienia.</span>
          </f7-block>
          <f7-block class="text-align-center">
            <f7-button @click="onBackHome" fill>Strona główna</f7-button>
          </f7-block>
        </div>


    </div>

        <div v-else>
          <f7-block class="text-align-center">
            <img style="height: 150px;" src="static/icons/whistle.svg" alt />
          </f7-block>
          <f7-block
            class="text-align-center"
          >Musisz zweryfikować swoje konto, aby móc wykupić pełny dostęp do wszystkich ćwiczeń i planów treningowych.</f7-block>
          <f7-block class="text-align-center">
            <f7-row>
              <f7-col>
                <f7-button href="/verified/" fill>Zweryfikuj konto</f7-button>
              </f7-col>
            </f7-row>
          </f7-block>
        </div>
      </div>
    </div>
    <toolbar-menu v-if="!openPage"/>

  </f7-page>
</template>

<script>
import SubscriptionsService from '@/services/SubscriptionsService';
import Loader from '@/components/Loader';
import ToolbarMenu from '@/components/ToolbarMenu'

import * as animationDataDone from '@/assets/lottie/braintree_done.json';
import * as animationDataCancel from '@/assets/lottie/braintree_cancel.json';

import axios from 'axios'
import Lottie from 'vue-lottie';

export default {
  components: {
    Loader,
    lottie: Lottie,
    ToolbarMenu
  },
  data() {
    return {
      defaultOptionsDone: {
        animationData: animationDataDone.default,
        loop: false
      },
      defaultOptionsCancel: {
        animationData: animationDataCancel.default,
        loop: false
      },
      loader: false,
      animationSpeed: 1,
      subscriptions: [],
      activeSubscription: {},
      amount: null,
      step: 'credit-card',
      selectSubscription: null,
      dropinInstance: '',
      token: '',
      loaderPay: false,
      openPage: false
    };
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'account');
    if (this.isAuthenticated && this.isVerified && !this.getDeviceIos) {
      this.getToken();
      this.fetchSubscriptions();
    }
  },
  methods: {
    async getToken() {
      this.loader = true;
      try {
        const response = await SubscriptionsService.braintreeToken();
        this.token = response.data.client_token
        this.initBraintree(response.data.client_token);
        this.loader = false;
      } catch (error) {
        console.log(error);
      }
    },
    initBraintree(token) {
      const dropin = require('braintree-web-drop-in');

      const dropinOptions = {
        authorization: token,
        selector: '#dropin-container',
        vaultManager: true,
        applePay: {
          displayName: 'Trening Piłkarski',
          paymentRequest: {
            total: {
              label: 'Trening Piłkarski',
              amount: this.amount.toString()
            }
          }
        },
        googlePay: {
          googlePayVersion: 2,
          merchantId: '10905887715206152915',
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPrice: this.amount.toString()  ,
            currencyCode: 'PLN'
          },
          allowedPaymentMethods: [{
            type: 'CARD',
          }]
        }
      }

      dropin.create(dropinOptions, (dropinError, dropinInstance) => {
        if (dropinError) {
          this.errorMessage = dropinError.message;
          this.$f7.toast
          .create({
            text: this.errorMessage,
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
          return;
        }
        this.dropinInstance = dropinInstance;
      });
    },
    dropinRequestPaymentMethod() {
      this.dropinInstance.requestPaymentMethod((requestErr, payload) => {
        
        if (requestErr) {
          this.errorMessage = requestErr.message;
          this.$f7.toast
          .create({
            text: this.errorMessage,
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
          this.loaderPay = false;
          return;
        }
        this.upgradePlan(payload.nonce);
      });
    },

    async fetchSubscriptions() {
      this.loader = true;
      try {
        const response = await SubscriptionsService.subscriptions();
        this.subscriptions = response.data.data.sort((a, b) => a.attributes.price - b.attributes.price);
        if (this.subscriptions.length > 0) {
          this.amount = this.subscriptions[0].attributes.price;
          this.selectSubscription = this.subscriptions[0].attributes._id;
        }
        this.loader = false;
      } catch (error) {
        console.log(error);
      }
    },
    async upgradePlan(nonce) {
      this.loaderPay = true;
      try {
        const response = await SubscriptionsService.subscribe(this.selectSubscription, nonce);
        this.$store.dispatch("apptreningpilkarski/setPlans", true);
        this.loaderPay = false;
        if (response.status == 201) {
          this.step = "done";
        }
      } catch (error) {
        console.log(error);
        this.step = "cancel";
      }
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    setPlan(type) {
      this.amount = type.attributes.price;
      this.selectSubscription = type.attributes._id;
    },
    onBackHome() {
      this.$f7router.navigate("/home/");
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/braintree.scss";
</style>
