export default {
  mounted() {
    document.addEventListener("deviceready", this.onDeviceReady, false);
  },
  methods: {
    onDeviceReady() {
      document.addEventListener("backbutton", this.onBackKeyDown, false);
    },
    onBackKeyDown(e) {
      e.preventDefault();
      this.$f7router.back();
    },
  }
}