<template>
  <f7-page login-screen>
    <f7-navbar title="Resetowanie hasła" back-link="Back"></f7-navbar>
    <f7-block style="margin-top: -50px; margin-bottom: -75px;">
      <lottie
        :options="defaultOptionsDone"
        :height="300"
        :width="300"
        v-on:animCreated="handleAnimation"
      />
    </f7-block>
    <f7-block class="text-align-center">
      <h4>Hasło zostało ustawione :)</h4>
      <span>Od teraz przy logowaniu będziesz używać nowego hasła.</span>
    </f7-block>
    <f7-block class="text-align-center">
      <f7-button @click="onBackToLogin" fill>Wróć do logowania</f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import * as animationDataDone from "@/assets/lottie/braintree_done.json";
import Lottie from "vue-lottie";

export default {
  components: {
    lottie: Lottie
  },
  data: () => {
    return {
      defaultOptionsDone: {
        animationData: animationDataDone.default,
        loop: false
      }
    };
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", '');
  },
  methods: {
    onBackToLogin() {
      this.$f7router.navigate("/login/");
    },
    handleAnimation(anim) {
      this.anim = anim;
    }
  }
};
</script>