<template>
  <f7-page login-screen>
    <f7-navbar title="Rejestracja" back-link="Back"></f7-navbar>
    <div v-if="step == 'register'">
      <f7-list form @submit.prevent="onRegister">
        <f7-list-input
          label="Email"
          type="email"
          placeholder="Email"
          :value="register.email"
          @input="register.email = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <f7-list-input
          label="Hasło"
          type="password"
          placeholder="Hasło"
          :value="register.password"
          @input="register.password = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <f7-list-input
          label="Potwierdź hasło"
          type="password"
          placeholder="Potwierdź hasło"
          :value="register.passwordConfirm"
          @input="register.passwordConfirm = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <div class="text-term">
          <f7-checkbox @change="onAcceptTerm" name="checkbox-term"></f7-checkbox>
          <span>
            Akceptuje
            <b @click="goRegulation()">regulamin</b> oraz <b @click="goPrivacyPolicy()">politykę prywatności</b> serwisu.
          </span>
        </div>
        <f7-block class="text-align-center">
          <f7-button v-if="!register.loader" type="submit" fill>Stwórz konto</f7-button>
          <f7-button v-else fill>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
    </div>
    <div v-if="step == 'success'">
      <f7-block class="text-align-center">
        <img style="height: 150px;" src="static/icons/sneaker.svg" alt />
      </f7-block>

      <f7-block class="text-align-center">
        <h4>Twoje konto zostało stworzone :)</h4>
        <span>Możesz teraz przejść na stronę główną lub zweryfikować swoje konto.</span>
      </f7-block>

      <f7-block v-if="!login.loader" class="text-align-center">
        <f7-row>
          <f7-col>
            <f7-button href="/home/" fill>Strona główna</f7-button>
          </f7-col>
          <f7-col>
            <f7-button href="/verified/" fill>Weryfikuj konto</f7-button>
          </f7-col>
        </f7-row>
      </f7-block>

    </div>
  </f7-page>
</template>

<script>
import authServices from "@/services/AuthService";
import jwt_decode from "jwt-decode";

export default {
  data: () => {
    return {
      step: "register",
      register: {
        email: null,
        password: null,
        passwordConfirm: null,
        term: false,
        loader: false
      },
      login: {
        loader: false
      }
    };
  },
  computed: {
    isConfirmPassword() {
      return this.register.password === this.register.passwordConfirm;
    }
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", '');
  },
  methods: {
    onAcceptTerm() {
      this.register.term = !this.register.term;
    },
    goRegulation() {
      this.$f7router.navigate("/regulation/");
    },
    goPrivacyPolicy() {
      this.$f7router.navigate("/privacy-policy/");
    },
    async onRegister() {
      if (!this.isConfirmPassword) {
        this.$f7.toast
          .create({
            text: "Hasła nie są identyczne",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
        return false;
      }

      if (!this.register.term) {
        this.$f7.toast
          .create({
            text: "Regulamin musi być zaakceptowany",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
        return false;
      }

      try {
        this.register.loader = true;
        const credentialRegister = {
          email: this.register.email,
          eula: true,
          password: this.register.password
        };

        const resposneRegister = await authServices.register(
          credentialRegister
        );
        if (resposneRegister.status == 201) {
          this.step = "success";
          this.onLogin();
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$f7.toast
            .create({
              text: error.response.data.errors[0].detail,
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        } else {
        this.$f7.toast
          .create({
            text:
              "Coś poszło nie tak. Spróbuj za jakiś czas lub skontaktuj się z administratorem.",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
        console.log(error);
        }
      }
      this.register.loader = false;
    },
    async onLogin() {
      try {
        this.login.loader = true;
        const credentialLogin = {
          email: this.register.email,
          password: this.register.password
        };

        const responseLogin = await authServices.login(credentialLogin);
        if (responseLogin.status == 200) {
          const dataToken = {
            token: responseLogin.data.token,
            exp: jwt_decode(responseLogin.data.token).exp,
          }
          this.$store.dispatch(
            "apptreningpilkarski/setToken",
            dataToken,
          );
          this.$store.dispatch(
            "apptreningpilkarski/setUser",
            jwt_decode(responseLogin.data.token).userId
          );
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text:
              "Coś poszło nie tak. Spróbuj za jakiś czas lub skontaktuj się z administratorem.",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.login.loader = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/login.scss";
</style>