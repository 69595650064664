<template>
  <div :class="[loader ? 'bgLoader' : '']">
    <div class="loader" v-if="loader"></div>
    <f7-app :params="f7params" >
      <f7-view main class="safe-areas" url="/"></f7-view>
    </f7-app>
  </div>
</template>

<script>
  import cordovaApp from './js/cordova-app.js';
  import routes from './routes';
  import { store } from './store'

  export default {
    data() {
      return {
        f7params: {
          id: 'com.TreningPilkarski',
          name: 'treningPilkarski',
          theme: 'auto',
          routes,
          input: {
            scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
            scrollIntoViewCentered: this.$device.cordova && !this.$device.electron,
          },
          statusbar: {
            overlay: this.$device.cordova && this.$device.ios || 'auto',
            iosTextColor: '#000000',
            androidTextColor: '#000000',
            iosBackgroundColor: '#ffffff',
            androidBackgroundColor: '#ffffff',
          },
        },
        loader: false,
      }
    },
    mounted() {
      this.$f7ready((f7) => {
        this.$store.dispatch("apptreningpilkarski/setDeviceIos", f7.device.ios);
        this.loader = true
        setTimeout(() => {
            this.loader = false
        }, 2000);
      });
    },
  }
</script>

<style lang="scss">
    .loader {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-size: 300px 100px !important;
        background: url('static/logo-trening-pilkarski.svg') center no-repeat;
    }
    .bgLoader {
        background: white;
        position: absolute;
    }
</style>