export default {
  namespaced: true,
  state: {
    token: null,
    exp: null,
    user: null,
    plans: false,
    verified: false,
    actualPage: '',
    app: {
      sound: true,
      deviceIos: null,
    }
  },
  mutations: {
    SET_TOKEN(state, dataToken) {
      state.token = dataToken.token;
      state.exp = dataToken.exp;
    },
    SET_EXP_TOKEN(state, exp) {
      state.exp = exp
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_PLAN(state, value) {
      state.plans = value
    },
    SET_VERIFIED(state, value) {
      state.verified = value
    },
    SET_APP_SOUND(state, value) {
      state.app.sound = value
    },
    SET_ACTUAL_PAGE(state, value) {
      state.actualPage = value
    },
    SET_DEVICE_IOS(state, value) {
      state.app.deviceIos = value
    }
  },
  actions: {
    setToken({ commit }, dataToken) {
      commit('SET_TOKEN', dataToken);
    },
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },
    setPlans({ commit }, value) {
      commit('SET_PLAN', value)
    },
    setVerified({ commit }, value) {
      commit('SET_VERIFIED', value)
    },
    setAppSound({ commit }, value) {
      commit('SET_APP_SOUND', value)
    },
    setActualPage({ commit }, value) {
      commit('SET_ACTUAL_PAGE', value)
    },
    setDeviceIos({ commit }, value) {
      commit('SET_DEVICE_IOS', value)
    }
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token && Date.now() < new Date(Date.now() + state.exp);
    },
    isPlans(state) {
      return state.plans
    },
    myUser(state) {
      return state.user
    },
    myToken(state) {
      return state.token == null ? '' : state.token;
    },
    isVerified(state) {
      return state.verified
    },
    getAppSound(state) {
      return state.app.sound
    },
    getActualPage(state) {
      return state.actualPage
    },
    getDeviceIos(state) {
      return state.app.deviceIos
    }
  }
}