import axios from 'axios'
let environment = '';
if (process.env.NODE_ENV == 'development') {
  environment = 'https://api.trening-pilkarski.pl'
}
if (process.env.NODE_ENV == 'production') {
  environment = 'https://api.trening-pilkarski.pl'
}

export default () => {
  return axios.create({
    baseURL: environment,
  })
}