<template>
  <f7-navbar :title="title" back-link="Back"></f7-navbar>
</template>

<script>
import onbackbutton from '@/mixins/onBackButton.js';

export default {
  mixins: [onbackbutton],
  name: 'navbar',
  props: {
    title: {
      type: String,
      required: true,
    }
  },
}
</script>