<template>
  <f7-page>
    <navbar :title="'Więcej'"></navbar>

    <f7-block class="menuPage">
      <f7-row>
        <f7-col width="50" tablet-width="33">
          <a href="/about/">
            <f7-card class="custom-card">
              <div>
                <img height="40px" src="static/icons/about.svg" alt />
                <p>O nas</p>
              </div>
            </f7-card>
          </a>
        </f7-col>
        <!-- <f7-col width="50" tablet-width="33">
          <a href="/about-app/">
            <f7-card class="custom-card">
              <div>
                <img height="40px" src="static/icons/app.svg" alt />
                <p>O aplikacji</p>
              </div>
            </f7-card>
          </a>
        </f7-col> -->
        <f7-col width="50" tablet-width="33">
          <a href="/regulation/">
            <f7-card class="custom-card">
              <div>
                <img height="40px" src="static/icons/regulations.svg" alt />
                <p>Regulamin</p>
              </div>
            </f7-card>
          </a>
        </f7-col>
        <f7-col width="50" tablet-width="33">
          <a href="/privacy-policy/">
            <f7-card class="custom-card">
              <div>
                <img height="40px" src="static/icons/policy.svg" alt />
                <p>Polityka prywatności</p>
              </div>
            </f7-card>
          </a>
        </f7-col>
        <f7-col width="50" tablet-width="33">
          <a href="/settings/">
            <f7-card class="custom-card">
              <div>
                <img height="40px" src="static/icons/settings.svg" alt />
                <p>Ustawienia</p>
              </div>
            </f7-card>
          </a>
        </f7-col>
        <f7-col width="50" tablet-width="33" v-if="!isPlans && !getDeviceIos">
          <a href="/plan-upgrade/">
            <f7-card class="custom-card">
              <div>
                <img height="40px" src="static/icons/cart.svg" alt />
                <p>Kup dostęp</p>
              </div>
            </f7-card>
          </a>
        </f7-col>
        <f7-col v-if="isAuthenticated" width="50" tablet-width="33">
          <a href="#" @click="onLogout">
            <f7-card class="custom-card">
              <div>
                <img height="40px" src="static/icons/logout.svg" alt />
                <p>Wyloguj</p>
              </div>
            </f7-card>
          </a>
        </f7-col>
      </f7-row>
    </f7-block>

    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>
<script>
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
    ToolbarPlans,
    ToolbarMenu
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'more');
    this.checkVerified(this.myToken)
  },
  methods: {
    onLogout() {
      this.$store.dispatch("apptreningpilkarski/setToken", {token: null, exp: null});
      this.$store.dispatch("apptreningpilkarski/setUser", null);
      this.$store.dispatch("apptreningpilkarski/setPlans", false);
      this.$store.dispatch("apptreningpilkarski/setVerified", false);
      this.$f7router.navigate("/home/");
    }
  }
};
</script>