<template>
  <f7-page login-screen>
    <f7-navbar title="Logowanie" back-link="Back"></f7-navbar>
    <f7-list form @submit.prevent="onLogin">
      <f7-list-input
        label="Email"
        type="email"
        placeholder="Email"
        :value="login.email"
        @input="login.email = $event.target.value"
        required
        validate
        error-message="Wymagane"
      ></f7-list-input>
      <f7-list-input
        label="Hasło"
        type="password"
        placeholder="Hasło"
        :value="login.password"
        @input="login.password = $event.target.value"
        required
        validate
        error-message="Wymagane"
      ></f7-list-input>
      <f7-block class="text-align-center">
        <f7-button v-if="!login.loader" type="submit" fill>Zaloguj się</f7-button>
        <f7-button v-else fill>
          <f7-preloader :size="20" color="white"></f7-preloader>
        </f7-button>
      </f7-block>
    </f7-list>
    <f7-block>
      <f7-link href="/forgot-password/" class="text-primary f-12">Zapomniałeś hasło?</f7-link>
    </f7-block>
    <f7-block class="text-align-center">
      <f7-button href="/register/" fill>Rejestracja</f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import authServices from "@/services/AuthService";
import userServices from "@/services/UserService";
import jwt_decode from "jwt-decode";

export default {
  data: () => {
    return {
      login: {
        email: null,
        password: null,
        loader: false
      }
    };
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", '');
  },
  methods: {
    async onLogin() {
      try {
        this.login.loader = true;
        const credentialLogin = {
          email: this.login.email,
          password: this.login.password
        };

        const responseLogin = await authServices.login(credentialLogin);
        if (responseLogin.status == 200) {
          const dataToken = {
            token: responseLogin.data.token,
            exp: jwt_decode(responseLogin.data.token).exp,
          }
          this.$store.dispatch(
            "apptreningpilkarski/setToken",
            dataToken 
          );
          this.$store.dispatch(
            "apptreningpilkarski/setUser",
            jwt_decode(responseLogin.data.token).userId
          );
          this.$store.dispatch("apptreningpilkarski/setPlans", true);
          this.checkVerified(responseLogin.data.token)
          this.$f7router.navigate("/home/");
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Niepoprawne dane logowania.",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.login.loader = false;
    }
  }
};
</script>