import Api from './index'
import { store } from '@/store'

export default {
  async latestWorkouts(count) {
    return Api().get(`workouts`, {
      params: {
        itemsPerPage: count,
      },
      headers: {
        'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
      }
    })
  },
  async listWorkouts(search, category, page) {
    return Api().get(`workouts`, {
      params: {
        title: search,
        'category.id': category,
        page: page
      },
      headers: {
        'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
      }
    })
  },
  async planWorkout(id) {
    return Api().get(`/workouts/${id}`, {
      headers: {
        'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
      }
    })
  },
  async categoryWorkout(id) {
    return Api().get(`/workout_categories/${id}`, {
      headers: {
        'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
      }
    })
  },
  async categoryWorkouts() {
    return Api().get(`/workout_categories`, {
      headers: {
        'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
      }
    })
  },
}