  
<template>
    <div class="timer" ref="timer">
        {{ secToTime(factor * seconds) }}
    </div>
</template>


<script>
import { secToTime } from "@/utils/common";
    export default {
        props: {
            initialValue: {
                type: Number,
                required: true,
                default: 0
            },
            paused: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                isMounted: false,
                value: this.initialValue,
            };
        },
        computed: {
            seconds() {
                return this.value
            },
            factor() {
                return this.value >= 0 ? 1 : -1
            },
        },
        mounted: function () {
            this.$nextTick(() => {
                this.isMounted = true;
                if(this.value){
                    const interval = setInterval(function () {
                        if(this.paused){
                            return;
                        }
                        const delta = 1;
                        this.value -= delta;
                        if(this.value === 0){
                            this.$emit('finish');
                        }
                        if(this.value <= 0){
                            this.value = 0;
                            clearInterval(interval);
                        }
                    }.bind(this), 1000);
                }
            });
        },
        methods: {
            secToTime(miliseconds) {
              return secToTime(miliseconds);
            },
        }

    }
</script>
