<template>
  <f7-page login-screen>
    <navbar :title="'Ustawienia'"></navbar>

    <f7-block>
      <f7-list simple-list>
        <f7-list-item>
          <span>Dźwięk w aplikacji</span>
          <f7-toggle :checked="this.settings.sound" @change="toggleDisplaySound()"></f7-toggle>
        </f7-list-item>
      </f7-list>
    </f7-block>

    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>

<script>
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
    ToolbarPlans,
    ToolbarMenu
  },
  data: () => {
    return {
      settings: {
        sound: null
      }
    };
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'more');
    this.settings.sound = this.getAppSound;
  },
  methods: {
    toggleDisplaySound() {
      this.settings.sound = !this.settings.sound;
      if (this.settings.sound) {
        this.$f7.toast
          .create({
            text: `Dźwięk w aplikacji został włączony`,
            position: "bottom",
            closeTimeout: 1500
          })
          .open();
          this.$store.dispatch("apptreningpilkarski/setAppSound", true);
      } else {
        this.$f7.toast
          .create({
            text: `Dźwięk w aplikacji został wyłączony`,
            position: "bottom",
            closeTimeout: 1500
          })
          .open();
          this.$store.dispatch("apptreningpilkarski/setAppSound", false);
      }
    }
  }
};
</script>