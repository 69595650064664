<template>
  <f7-toolbar v-if="!isPlans" position="top" class="not-verified-toolbar">
    <f7-link class="w-100" href="/verified/"><f7-icon f7="at"></f7-icon> Weryfikuj konto</f7-link>
  </f7-toolbar>
</template>

<script>

export default {
  name: 'toolbar-verified',
}
</script>