<template>
  <f7-page login-screen>
    <navbar :title="'Weryfikacja konta'"></navbar>

    <f7-block style="margin-top: -50px; margin-bottom: -75px;">
      <lottie
        :options="defaultOptionsDone"
        :height="300"
        :width="300"
        v-on:animCreated="handleAnimation"
      />
    </f7-block>
    <f7-block class="text-align-center">
      <h4>Konto zostało zweryfikowane poprawnie :)</h4>
      <span>Od teraz możesz w pełni korzystać z konta.</span>
    </f7-block>
    <f7-block class="text-align-center">
      <f7-button @click="onBackHome" fill>Wróć na stronę główną</f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import * as animationDataDone from "@/assets/lottie/braintree_done.json";
import Lottie from "vue-lottie";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    lottie: Lottie,
    Navbar
  },
  data: () => {
    return {
      defaultOptionsDone: {
        animationData: animationDataDone.default,
        loop: false
      }
    };
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", '');
  },
  methods: {
    onBackHome() {
      this.$f7router.navigate("/home/");
    },
    handleAnimation(anim) {
      this.anim = anim;
    }
  }
};
</script>