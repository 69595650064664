<template>
  <f7-page login-screen>
    <navbar :title="'Weryfikacja konta'"></navbar>

    <f7-block class="text-align-center">
      <img style="height: 150px;" src="static/icons/whistle.svg" alt />
    </f7-block>

    <f7-block class="text-align-center">
      <span>Na adres email użyty podczas rejestracji został wysłany kod aktywacyjny, który należy wpisać poniżej w celu zweryfikowania konta.</span>
    </f7-block>

    <f7-block class="text-align-center">
        <f7-button @click="onResendCode()">Wyślij kod ponownie</f7-button>
    </f7-block>

    <f7-list form @submit.prevent="onVerificationAccount">
      <f7-list-input
        label="Kod"
        type="text"
        placeholder="xxxx"
        :value="verification.code"
        @input="verification.code = $event.target.value"
        required
        validate
        error-message="Wymagane"
      ></f7-list-input>
      <f7-block class="text-align-center">
        <f7-button v-if="!verification.loader" type="submit" fill>Weryfikuj konto</f7-button>
        <f7-button v-else fill>
          <f7-preloader :size="20" color="white"></f7-preloader>
        </f7-button>
      </f7-block>
    </f7-list>

  </f7-page>
</template>
<script>
import userServices from "@/services/UserService";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar
  },
    data: () => {
    return {
      verification: {
        code: null,
        loader: false
      }
    };
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", '');
  },
  methods: {
    async onResendCode() {
      try {
        const responseMe = await userServices.meResendVerificationCode();
        this.$f7.toast
          .create({
            text: "Kod został wysłany ponownie.",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
      } catch (error) {
        console.log(error);
      }
    },
    async onVerificationAccount() {
      this.verification.loader = true;
      try {
        const data = {
          code: this.verification.code
        };

        const responseVerification = await userServices.meVerify(data);
        if (responseVerification.status == 200) {
          this.$store.dispatch("apptreningpilkarski/setVerified", true);
          this.$f7router.navigate("/verified-success/");
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$f7.toast
            .create({
              text: error.response.data.errors[0].detail,
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        } else if (error.response.status == 422) {
          this.$f7.toast
            .create({
              text: error.response.data.description,
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        } else {
          this.$f7.toast
            .create({
              text:
                "Coś poszło nie tak. Spróbuj za jakiś czas lub skontaktuj się z administratorem.",
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
      }
      this.verification.loader = false;
    },
  }
};
</script>