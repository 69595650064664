<template>
    <f7-toolbar class="menu-toolbar" tabbar labels :position="'bottom'">
      <f7-link href="/home/" :class="{ active: getActualPage == 'home' }">
        <div class="wrapper">
            <div>
                <img src="static/icons/home.svg" alt="">
                <p>Home</p>
            </div>
        </div>
      </f7-link>
      <f7-link href="/categories-plans-training/" :class="{ active: getActualPage == 'plans-training' }">
        <div class="wrapper">
            <div>
                <img src="static/icons/exercises.svg" alt="">
                <p>Treningi</p>
            </div>
        </div>
      </f7-link>
      <f7-link href="/categories-exercises/" :class="{ active: getActualPage == 'exercises' }">
        <div class="wrapper">
            <div>
            <img src="static/icons/plans.svg" alt="">
            <p>Ćwiczenia</p>
          </div>
        </div>
      </f7-link>
      <f7-link href="/account/" :class="{ active: getActualPage == 'account' }">
        <div class="wrapper">
            <div>
            <img src="static/icons/account.svg" alt="">
            <p><span>Twoje </span>konto</p>
        </div>
        </div>
      </f7-link>
      <f7-link href="/more-menu/" :class="{ active: getActualPage == 'more' }">
        <div class="wrapper">
            <div>
            <img src="static/icons/more.svg" alt="">
            <p>Więcej</p>
        </div>
        </div>
      </f7-link>
    </f7-toolbar>
</template>

<script>

export default {
  name: 'toolbar-menu',
}
</script>