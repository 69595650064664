<template>
  <f7-page>
    <f7-navbar title="Ćwiczenia" back-link="Back">
      <f7-nav-right v-if="playerReady">
        <f7-link @click="toggleSound(true)">
          <img v-if="!soundIsActive" height="30px" src="static/icons/player/volume.svg" alt />
          <img v-else height="30px" src="static/icons/player/mute.svg" alt />
        </f7-link>
      </f7-nav-right>

    </f7-navbar>

    <loader v-if="loader"></loader>

    <div v-else class="page-plan-training">

      <div class="wrapperMedia" v-if="exercise.videos">
        <div class="after">
          <img height="50px" src="static/icons/video.svg">
          <p>Ładowanie filmu</p>
        </div>
        <div class="embed-container">
          <div class="wrapper-loader-vimeo wrapper-loader-vimeo-exercise">
            <loader v-if="loaderVimeo"></loader>
          </div>

          <vueVimeoPlayer v-if="exercise.videos.length > 0"
            ref="player"
            :videoUrl="exercise.videos[0].src"
            :videoId="exercise.videos[0].src"
            :options="optionsVimeo"
            :loop="true"
            :controls="false"
            @ready="onReady"
            @play="onPlay"
            :player-height="400"
            :player-width="800"
          ></vueVimeoPlayer>
        </div>
      </div>
      <f7-block class="wrapper-card" v-if="exercise">
        <f7-card class="plan-card">
          <f7-card-header class="no-border" valign="center">
            <div class="content exerciseCard">
              <div>
                <p class="title" style="margin-bottom: 10px">{{exercise.title}}</p>
                <p class="extra">
                  <span>Kategoria:</span>
                  {{exercise.category}}
                </p>
                <!-- <p class="extra">
                  <span>Data utworzenia:</span>
                  {{exercise.createdAt | formatDate}}
                </p>
                <p class="extra">
                  <span>Poziom:</span>
                  {{exercise.difficulty}}
                </p>
                <p class="extra">
                  <span>Typ:</span>
                  {{exercise.durationType}}
                </p>
                <p class="extra">
                  <span>Tagi:</span>
                  <f7-chip v-for="tag in exercise.tags" :key="tag.id" outline :text="tag.name"></f7-chip>
                </p> -->
              </div>
            </div>
          </f7-card-header>

          <f7-card-content>
            <p class="description">{{exercise.description}}</p>
          </f7-card-content>
        </f7-card>
      </f7-block>
    </div>

    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import ExercisesService from "@/services/ExercisesService";
import Loader from "@/components/Loader";
import onbackbutton from '@/mixins/onBackButton.js';

export default {
  mixins: [onbackbutton],
  props: {
    idCategory: {
      type: String,
      required: true
    },
    idExercise: {
      type: String,
      required: true
    }
  },
  components: {
    vueVimeoPlayer,
    Loader,
    ToolbarPlans,
    ToolbarMenu
  },
  data: () => {
    return {
      optionsVimeo: {
        title: false,
        byline: false,
        portrait: false,
        sidedock: false,
        controls: false,
      },
      exercise: {},
      playerReady: false,
      loader: false,
      soundIsActive: null,
      playIsActive: true,
      loaderVimeo: true
    };
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'exercises');
    this.fetchExercise();
  },
  methods: {
    async fetchExercise() {
      this.loader = true;
      try {
        const response = await ExercisesService.exercise(this.idExercise);
        this.exercise = response.data.data.attributes;
        this.exercise.category = response.data.included[0].attributes.name;
        this.loader = false;
      } catch (error) {
        if (error.response.status == 403 || error.response.status == 401) {
            this.$f7.toast
            .create({
                text: "Brak uprawnień do wyświetlenia ćwiczenia",
                position: "bottom",
                closeTimeout: 5000
            })
            .open();
            this.$f7router.back();
        }
        console.log(error);
      }
    },
    onPlay() {
      this.loaderVimeo = false
    },
    onReady() {
      this.playerReady = true
      this.soundIsActive = this.getAppSound;
      this.toggleSound(false);
      
      this.$refs.player.play();
    },
    togglePlay() {
      if (this.playIsActive) {
        this.$refs.player.play();
        this.playIsActive = !this.playIsActive;
      } else {
        this.$refs.player.pause();
        this.playIsActive = !this.playIsActive;
      }
    },
    toggleSound(click) {
      this.soundIsActive = !this.soundIsActive;
      if (this.soundIsActive) {
        if (click) {
          this.$refs.player.mute();
          this.$f7.toast
            .create({
              text: `Dźwięk został wyłączony`,
              position: "bottom",
              closeTimeout: 1500
            })
            .open();
        }
      } else {
        this.$refs.player.unmute();
        if (click) {
          this.$f7.toast
            .create({
              text: `Dźwięk został włączony`,
              position: "bottom",
              closeTimeout: 1500
            })
            .open();
        }
      }
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/plansTraining.scss";
@import "@/assets/scss/pages/video.scss";
</style>
