<template>
  <f7-page login-screen>
    <navbar :title="'Konto'"></navbar>

    <loader v-if="loader"></loader>

    <div v-else>
      <div class="info-braintree">
        <f7-block>
          <h3>Informacje o subskrypcji</h3>
          <p v-if="isPlans">Typ subskrypcji: <span>{{plan.type}}</span></p>
          <p v-if="!plan.existActivePlan || plan.nextChargeAt">Status: <span v-if="plan.status" class="status-true">Automatyczne odnowienie</span><span v-else class="status-false">Nieaktywna</span></p>
          <p v-if="isPlans && plan.expiredAt">Data wygaśnięcia usługi: <span>{{plan.expiredAt | formatDate}}</span></p>
          <p v-if="isPlans && plan.nextChargeAt">Data następnej transakcji: <span>{{plan.nextChargeAt | formatDate}}</span></p>
        </f7-block>
        <f7-block v-if="plan.existActivePlan && plan.nextChargeAt">
          <f7-button
            v-if="!loaderCancel"
            fill
            class="button-red"
            @click.prevent="onCancellationSubscription"
          >Anuluj subskrypcje</f7-button>
          <f7-button v-else fill class="button-red">
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
        <f7-block v-if="!plan.existActivePlan && !getDeviceIos">
          <f7-button
            fill
            @click="goToUpgradePlan"
            class="button-blue"
          >Kup dostęp</f7-button>
        </f7-block>
        
      </div>
      <f7-block>
        <h3>Podstawowe informacje</h3>
      </f7-block>
      <f7-list form @submit.prevent="onUpdateInformationAccount">
        <f7-list-input
          label="Email"
          type="text"
          placeholder="Your email"
          :value="account.email"
          disabled
        ></f7-list-input>
      </f7-list>
      <f7-block>
        <h3>Zmiana hasła</h3>
      </f7-block>
      <f7-list form @submit.prevent="onChangePassword">
        <f7-list-input
          label="Stare hasło"
          type="password"
          placeholder="Stare hasło"
          :value="account.passwordOld"
          @input="account.passwordOld = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <f7-list-input
          label="Nowe hasło"
          type="password"
          placeholder="Nowe hasło"
          :value="account.passwordNew"
          @input="account.passwordNew = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <f7-list-input
          label="Potwierdź nowe hasło"
          type="password"
          placeholder="Potwierdź nowe hasło"
          :value="account.passwordNewConfirm"
          @input="account.passwordNewConfirm = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <f7-block class="text-align-center">
          <f7-button v-if="!account.loaderPassword" type="submit" fill>Zmień hasło</f7-button>
          <f7-button v-else fill>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
    </div>

    <toolbar-verified v-if="!isVerified"/>
    <toolbar-menu />
  </f7-page>
</template>

<script>
import userServices from "@/services/UserService";
import ToolbarVerified from "@/components/ToolbarVerified.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import Navbar from "@/components/Navbar.vue";
import Loader from "@/components/Loader";
import SubscriptionsService from '@/services/SubscriptionsService';

export default {
  components: {
    Navbar,
    Loader,
    ToolbarVerified,
    ToolbarMenu
  },
  data: () => {
    return {
      loader: false,
      account: {
        email: "",
        passwordOld: "",
        passwordNew: "",
        passwordNewConfirm: "",
        loaderUpdate: false,
        loaderPassword: false
      },
      loaderCancel: false,
      plan: {
        type: null,
        status: false,
        expiredAt: null,
        nextChargeAt: null,
        existActivePlan: null,
      }
    };
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'account');
    this.checkVerified(this.myToken)
    this.fetchUser();
  },
  computed: {
    isConfirmNewPassowrd() {
      return this.account.passwordNew === this.account.passwordNewConfirm;
    }
  },
  methods: {
    goToUpgradePlan() {
      this.$f7router.navigate("/plan-upgrade/");
    },
    async fetchUser() {
      this.loader = true;
      try {
        const responseUser = await userServices.user(this.myUser);
        this.account.email = responseUser.data.data.attributes.email;
        this.plan.existActivePlan = responseUser.data.data.attributes.activeSubscriptions.length > 0
        if (this.plan.existActivePlan) {
          this.plan.type = responseUser.data.data.attributes.activeSubscriptions[0].braintreeSubscription.data.attributes.name;
          this.plan.nextChargeAt = responseUser.data.data.attributes.activeSubscriptions[0].nextBillingDate;
          this.plan.expiredAt = responseUser.data.data.attributes.activeSubscriptions[0].expiredAt;
          this.plan.status = new Date(responseUser.data.data.attributes.activeSubscriptions[0].nextBillingDate) > Date.now();
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text:
              "Coś poszło nie tak. Spróbuj za jakiś czas lub skontaktuj się z administratorem.",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.loader = false;
    },
    onUpdateInformationAccount() {},
    async onChangePassword() {
      if (!this.isConfirmNewPassowrd) {
        this.$f7.toast
          .create({
            text: "Hasła nie są identyczne",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
        return false;
      }

      try {
        this.account.loaderPassword = true;
        const dataPassword = {
          oldPassword: this.account.passwordOld,
          password: this.account.passwordNew
        };
        const responseUserPassword = await userServices.updateUser(
          this.myUser,
          dataPassword
        );
        if (responseUserPassword.status == 200) {
          this.$f7.toast
            .create({
              text: "Hasło zostało zmienione.",
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
        }
        if (responseUserPassword.status == 400) {
        }
      } catch (error) {
        if (error.response.status == 409) {
          this.$f7.toast
            .create({
              text: error.response.data.description,
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
        } else if (error.response.status == 400) {
          this.$f7.toast
            .create({
              text: error.response.data.errors[0].detail,
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
        } else {
          this.$f7.toast
            .create({
              text:
                "Coś poszło nie tak. Spróbuj za jakiś czas lub skontaktuj się z administratorem.",
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.account.loaderPassword = false;
    },
    async onCancellationSubscription() {
      this.loaderCancel = true;
      try {
        const responseCancellation = await SubscriptionsService.cancelSubscription();
        this.$store.dispatch("apptreningpilkarski/setPlans", true);
        if (responseCancellation.status == 200) {
          this.plan.expiredAt = this.plan.nextChargeAt;
          this.plan.nextChargeAt = null;
          this.plan.status = false;
        }
      } catch (error) {
        if (error.response.status == 403) {
            this.$f7.toast
            .create({
              text: 'Brak aktywnych subskrypcji. Skontaktuj się z supportem w celu wyjaśnienia.',
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
        }
        console.log(error);
      }
      this.loaderCancel = false;
    },
  }
};
</script>

<style lang="scss">
.info-braintree {
  span {
    font-weight: 700;
  }
  .status {
    font-weight: 700;
    &-true {
      color: green;
    }
    &-false {
      color: red;
    }
  }
  .button-blue {
    background: #007aff !important;
  }
  .button-red {
    background: red !important;
  }
}
</style>