<template>
  <f7-page
    infinite
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <navbar :title="'Ćwiczenia'"></navbar>
     
     <f7-block class="page-plan-training">
      <div class="category-card">
        <div class="content">
          <div>
            <p class="name">{{category.attributes.name}}</p>
          </div>
        </div>
      </div>
    </f7-block>
    <f7-block v-if="isAuthenticated" class="search-box">
      <f7-list no-hairlines-md>
        <f7-list-input
          type="text"
          @input="search = $event.target.value"
          v-model="search"
          placeholder="Szukaj ćwiczenia..."
          clear-button
        ></f7-list-input>
      </f7-list>
    </f7-block>

    <loader v-if="loader"></loader>

    <f7-block v-else class="page-plan-training">
      <div v-if="list.length > 0">
        <a
          v-for="exercise in list"
          :key="exercise.id"
          v-show="showAccessItem(exercise.attributes.access)"
          :href="exercise.attributes.access ? `/categories-exercises/${idCategory}/exercise/${exercise.attributes._id}/` : '/plan-upgrade/'"
        >
          <f7-card class="plan-card">
            <f7-card-header class="no-border" valign="center">
              <img v-if="exercise.attributes.cover !== null" class="list" :src="`${host}${exercise.attributes.cover.path}`" alt />
              <div v-if="!exercise.attributes.access" class="access">
                <img
                  src="static/icons/lock.svg"
                  alt="lock"
                />
              </div>
              <div class="content">
                <div>
                  <p class="title">{{exercise.attributes.title}}</p>
                  <p class="extra">
                    <span>Dostęp:</span>
                    {{exercise.attributes.public ? 'Darmowy' : 'Płatny'}}
                  </p>
                </div>
              </div>
            </f7-card-header>
          </f7-card>
        </a>
      </div>
      <div v-else>
        <f7-block class="text-align-center">
          <img style="height: 150px;" src="static/icons/bag.svg" alt />
        </f7-block>
        <f7-block class="text-align-center">Brak ćwiczeń w bazie.</f7-block>
      </div>
    </f7-block>

    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>

<script>
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import Loader from "@/components/Loader";
import ExercisesService from "@/services/ExercisesService";
import debounce from "lodash.debounce";
import Navbar from "@/components/Navbar";

export default {
  props: {
    idCategory: {
      type: String,
      required: true
    }
  },
  components: {
    Navbar,
    ToolbarPlans,
    ToolbarMenu,
    Loader
  },
  data: () => {
    return {
      loader: false,
      list: [],
      count: null,
      search: "",
      page: 1,
      category: {
        attributes: {
          name: "",
        }
      },
      allowInfinite: true,
      showPreloader: false,
      infiniteScrollCount: 0
    };
  },
  watch: {
    search: debounce(function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchListExercises(this.search, this.idCategory, 1);
      }
    }, 1000),
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'exercises');
    this.fetchCategoryExercise(this.idCategory);
    this.fetchListExercises(this.search, this.idCategory, this.page);
  },
  computed: {
    existMoreLoadItem() {
      return this.list.length < this.count;
    }
  },
  methods: {
    showAccessItem(access) {
      if (this.getDeviceIos && !access) {
        return false
      } else {
        return true
      }
    },
    async fetchCategoryExercise(id) {
      try {
        const response = await ExercisesService.categoryExercise(id);
        this.category = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchListExercises(search, category, page) {
      this.loader = true;
      try {
        const response = await ExercisesService.listExercises(
          search,
          category,
          page
        );
        this.list = response.data.data;
        this.count = response.data.meta.totalItems;
        this.loader = false;
        this.showPreloader = this.existMoreLoadItem;
        this.infiniteScrollCount = 0;
      } catch (error) {
        console.log(error);
      }
    },
    async loadMore() {
      this.infiniteScrollCount++;
      if (this.existMoreLoadItem && this.infiniteScrollCount == 1) {
        this.page = this.page + 1;
        try {
          const response = await ExercisesService.listExercises(
            this.search,
            this.idCategory,
            this.page
          );
          this.list = [...this.list, ...response.data.data];
          this.showPreloader = this.existMoreLoadItem;
          this.infiniteScrollCount = 0;
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
};
</script>