import Vue from 'vue';
import Framework7 from './js/framework7-custom.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
import './css/framework7-custom.less';
import './css/icons.css';
import './css/app.less';
import App from './App.vue';
import Filter from './filter'
import { store }  from './store'
import mixinAuth from './mixins/mixinAuth'
import vueVimeoPlayer from 'vue-vimeo-player'

Framework7.use(Framework7Vue);

Vue.mixin(mixinAuth)
Vue.use(Filter)
Vue.use(vueVimeoPlayer)

new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  components: {
    App
  },
});