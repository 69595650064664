<template>
  <f7-page login-screen>
    <f7-navbar title="Resetowanie hasła" back-link="Back"></f7-navbar>
    <div v-if="step == 'forgot'">
      <f7-list form @submit.prevent="onRemindPassword">
        <f7-list-input
          label="Email"
          type="email"
          placeholder="Email"
          :value="account.email"
          @input="account.email = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <f7-block class="text-align-center">
          <f7-button v-if="!account.loaderForgot" type="submit" fill>Ustaw nowe hasło</f7-button>
          <f7-button v-else fill>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
    </div>
    <div v-if="step == 'code'">
      <f7-block class="text-align-center">
        <img style="height: 150px;" src="static/icons/whistle.svg" alt />
      </f7-block>

      <f7-block class="text-align-center">
        <span>Na podany adres email został wysłany kod aktywacyjny, który należy wpisać poniżej w celu ustawienia nowego hasła do konta.</span>
      </f7-block>

      <f7-list form @submit.prevent="onVerification">
        <f7-list-input
          label="Kod"
          type="text"
          placeholder="xxxx"
          :value="verification.code"
          @input="verification.code = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <f7-block class="text-align-center">
          <f7-button v-if="!verification.loader" type="submit" fill>Weryfikuj konto</f7-button>
          <f7-button v-else fill>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
    </div>
    <div v-if="step == 'reset'">
      <f7-list form @submit.prevent="onResetPassword">
        <f7-list-input
          label="Nowe hasło"
          type="password"
          placeholder="Nowe hasło"
          :value="account.passwordNew"
          @input="account.passwordNew = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <f7-list-input
          label="Potwierdź nowe hasło"
          type="password"
          placeholder="Potwierdź nowe hasło"
          :value="account.passwordNewConfirm"
          @input="account.passwordNewConfirm = $event.target.value"
          required
          validate
          error-message="Wymagane"
        ></f7-list-input>
        <f7-block class="text-align-center">
          <f7-button v-if="!account.loaderReset" type="submit" fill>Ustaw nowe hasło</f7-button>
          <f7-button v-else fill>
            <f7-preloader :size="20" color="white"></f7-preloader>
          </f7-button>
        </f7-block>
      </f7-list>
    </div>
  </f7-page>
</template>
<script>
import authServices from "@/services/AuthService";

export default {
  data: () => {
    return {
      step: "forgot",
      account: {
        email: null,
        loaderForgot: false,
        loaderReset: false,
        passwordNew: "",
        passwordNewConfirm: ""
      },
      verification: {
        code: null,
        loader: false
      }
    };
  },
  computed: {
    isConfirmNewPassowrd() {
      return this.account.passwordNew === this.account.passwordNewConfirm;
    }
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", '');
  },
  methods: {
    async onRemindPassword() {
      this.account.loaderForgot = true;
      try {
        const credentialForgotPassword = {
          email: this.account.email
        };

        const responseForgotPassword = await authServices.requestResetPassword(
          credentialForgotPassword
        );
        if (responseForgotPassword.status == 201) {
          this.step = "code";
        }
      } catch (error) {
        if (error.resposne.status == 400) {
          this.$f7.toast
            .create({
              text: error.response.data.errors[0].detail,
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        } else {
          this.$f7.toast
            .create({
              text:
                "Coś poszło nie tak. Spróbuj za jakiś czas lub skontaktuj się z administratorem.",
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
      }
      this.account.loaderForgot = false;
    },
    async onVerification() {
      this.verification.loader = true;
      try {
        const credentialCode = {
          email: this.account.email,
          code: this.verification.code
        };

        const responseVerification = await authServices.validatePasswordResetCode(
          credentialCode
        );
        if (responseVerification.status == 200) {
          this.step = "reset";
        }
      } catch (error) {
        if (error.response.status == 422) {
          this.$f7.toast
            .create({
              text: error.response.data.description,
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        } else {
          this.$f7.toast
            .create({
              text:
                "Coś poszło nie tak. Spróbuj za jakiś czas lub skontaktuj się z administratorem.",
              position: "bottom",
              closeTimeout: 3000
            })
            .open();
          console.log(error);
        }
      }
      this.verification.loader = false;
    },
    async onResetPassword() {
      if (!this.isConfirmNewPassowrd) {
        this.$f7.toast
          .create({
            text: "Hasła nie są identyczne",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
        return false;
      }

      this.account.loaderReset = true;
      try {
        const credentialResetPassword = {
          email: this.account.email,
          code: this.verification.code,
          password: this.account.passwordNew
        };

        const responseResetPassword = await authServices.resetPassword(
          credentialResetPassword
        );
        if (responseResetPassword.status == 201) {
          this.$f7router.navigate("/forgot-password-success/");
        }
      } catch (error) {
        this.$f7.toast
          .create({
            text:
              "Coś poszło nie tak. Spróbuj za jakiś czas lub skontaktuj się z administratorem.",
            position: "bottom",
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
      this.account.loaderReset = true;
    }
  }
};
</script>