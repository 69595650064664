<template>
  <f7-page>
    <navbar :title="'O nas'"></navbar>

    <div class="text-wrapper">
      <f7-block>
        <div class="image">
          <img height="100px" src="static/logo-trening-pilkarski.svg" alt />
        </div>
      </f7-block>
      <f7-block>
        <p><span>Trening-Piłkarski.pl</span> jest unikatowym stowarzyszeniem działającym na rzecz szeroko pojętego rozwoju sportowego i osobowościowego młodych piłkarzy. Od 6 lat prowadzimy <span>blog o tematyce piłkarskiej</span>, dzięki któremu inspirujemy, motywujemy i doradzamy zarówno piłkarzom i ich rodzicom, ale także trenerom. Blog jest wyrazem naszego subiektywnego spojrzenia na trening piłkarski i <span>wszystko</span> co jest z nim związane. W naszym przekonaniu w procesie treningowym wszystko ma ogromne znaczenie, a sam trening piłkarski trwa 24 godziny na dobę. Dlatego na blogu poruszamy wszelkie kwestie szczegółowe związane z treningiem piłkarskim i prawidłowym rozwojem młodych piłkarzy. Cały czas rozwijamy się dla naszych czytelników.</p>
        <p>Piłka nożna jest bez wątpienia <span>najpopularniejszą dyscypliną sportu na świecie</span>, która zawiera również szeroki aspekt społeczny. Dlatego bardzo ważne jest odpowiednie zrozumienie football’u oraz kreowanie odpowiednich postaw i wartości oraz odpowiedniego środowiska dla rozwoju dzieci i młodzieży w procesie treningowym. Może się to odbywać tylko pod wpływem odpowiedniej świadomości osób uczestniczących w tym procesie.</p>
        <p>Jak wspomnieliśmy na wstępie <span>wszystko ma znaczenie</span>, dlatego na blogu oprócz dużej dawki ciekawostek i nowości dotyczących zarówno zespołowego jak i indywidualnego treningu piłkarskiego, można znaleźć również informacje na temat aspektów pośrednio dotyczących treningu takich jak: wzbudzanie odpowiedniej motywacji i nastawienia do treningu czy meczów, rozumienia zasad odpowiedniego przygotowania fizycznego, zasad odpowiedniego odżywiania sportowców i o wiele, wiele więcej. Dlatego też można u nas znaleźć zarówno gotowe profesjonalnie przygotowane plany treningowe jak również skorzystać z indywidualnej konsultacji oraz treningu indywidualnego zarówno w aspekcie przygotowania czysto fizycznego jak i przede wszystkim kompleksowego <span>treningu indywidualnych</span> umiejętności piłkarskich. Przez ostatnie lata przygotowaliśmy szereg narzędzi pomocnych w udoskonalaniu treningu piłkarskiego, zarówno dla zawodników jak i trenerów tj. <span>Poradnik Piłkarza, Dziennik Piłkarza, Zeszyt Trenera</span> czy poradnik o odżywianiu <span>Piłkarska Kuchnia</span>. Organizujemy również szkolenia i warsztaty dla trenerów oraz staże trenerskie, dając trenerom w ten sposób możliwość zdobywania szerszej wiedzy, wymiany poglądów oraz rozwoju swoich kompetencji. Nowością jest stworzenie aplikacji na komórkę <span>Trening Piłkarski</span> gdzie każdy zawodnik dzięki niej może rozwijać swoje umiejętności piłkarskie.</p>
        <p>Chcemy dzielić się swoimi spostrzeżeniami, zdobytą wiedzą, ale też doświadczeniem wyniesionym z dotychczasowych kontaktów z treningiem piłkarskim w kraju jak i za granicą.</p>
        <p>Ideą bloga jest też <span>dzielić się pasją do footballu</span> i zmieniać postrzeganie treningu piłkarskiego na pełniejsze, bardziej kompleksowe, zwracając jednocześnie szczególną uwagę na każdy detal, jakże istotny w procesie rozwoju młodych sportowców.</p>
        <p>Mamy nadzieję i przekonanie, że każdy z was wyniesie od nas dużo merytorycznych informacji, wiele pozytywnych wrażeń i wartościowych wskazówek. Jeśli jesteś piłkarzem, trenerem, rodzicem, zarządzasz klubem piłkarskim lub po prostu kochasz piłkę nożną zachęcamy do odwiedzania naszego bloga, kanałów social media (FB, Instagram, Youtube) oraz pobierania aplikacji.</p>
        <p>Życzymy wielu ciekawych inspiracji, a także <span>motywacji do dalszego własnego rozwoju.</span></p>
      </f7-block>
    </div>

    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>

<script>
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
    ToolbarPlans,
    ToolbarMenu
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'more');
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/text.scss";
</style>