<template>
  <f7-page>
    <navbar :title="'O aplikacji'"></navbar>

    <div class="text-wrapper">
      <f7-block>
        <div class="image">
          <img height="100px" src="static/logo-trening-pilkarski.svg" alt />
        </div>
      </f7-block>
      <f7-block>
      </f7-block>
    </div>

    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>

<script>
import ToolbarPlans from "@/components/ToolbarPlans.vue";
import ToolbarMenu from "@/components/ToolbarMenu.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
    ToolbarPlans,
    ToolbarMenu
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'more');
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/text.scss";
</style>