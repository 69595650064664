<template>
  <f7-page
    infinite
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <navbar :title="'Treningi'"></navbar>

    <f7-block class="page-plan-training">
      <div class="category-card">
        <div class="content">
          <div>
            <p class="name">{{category.attributes.name}}</p>
            <p class="description">{{category.attributes.description}}</p>
          </div>
        </div>
      </div>
    </f7-block>
    <f7-block v-if="isAuthenticated" class="search-box">
      <f7-list no-hairlines-md>
        <f7-list-input
          type="text"
          @input="search = $event.target.value"
          v-model="search"
          placeholder="Szukaj planu treningowego..."
          clear-button
        ></f7-list-input>
      </f7-list>
    </f7-block>

    <loader v-if="loader"></loader>

    <div v-else>

      <f7-block class="page-plan-training">

        <div v-if="list.length > 0">
          <a
            v-for="plan in list"
            :key="plan.id"
            v-show="showAccessItem(plan.attributes.access)"
            :href="plan.attributes.access ? `/categories-plans-training/${idCategory}/plan/${plan.attributes._id}` : '/plan-upgrade/'"
          >
            <f7-card class="plan-card">
              <f7-card-header class="no-border" valign="center">
                <img v-if="plan.attributes.thumbnail !== null" class="list" :src="`${host}${plan.attributes.thumbnail.path}`" alt />
                <div v-if="!plan.attributes.access" class="access">
                  <img
                    src="static/icons/lock.svg"
                    alt="lock"
                  />
                </div>
                <div class="content">
                  <div>
                    <p class="title">{{plan.attributes.title}}</p>
                    <p class="extra">
                      <span>Dostęp:</span>
                      {{plan.attributes.public ? 'Darmowy' : 'Płatny'}}
                    </p>
                    <p class="extra">
                      <span>Czas:</span>
                      {{secToTime(plan.attributes.totalTime)}}
                    </p>
                  </div>
                </div>
              </f7-card-header>
            </f7-card>
          </a>
        </div>
        <div v-else>
          <f7-block class="text-align-center">
            <img style="height: 150px;" src="static/icons/bag.svg" alt />
          </f7-block>
          <f7-block class="text-align-center">Brak planów treningowych w bazie.</f7-block>
        </div>
      </f7-block>
    </div>

    <toolbar-plans />
    <toolbar-menu />
  </f7-page>
</template>

<script>
import WorkoutsService from "@/services/WorkoutsService";
import ToolbarPlans from "@/components/ToolbarPlans";
import ToolbarMenu from "@/components/ToolbarMenu";
import Navbar from "@/components/Navbar";
import Loader from "@/components/Loader";
import { secToTime } from "@/utils/common";
import debounce from "lodash.debounce";

export default {
  props: {
    idCategory: {
      type: String,
      required: true
    }
  },
  components: {
    Navbar,
    ToolbarPlans,
    ToolbarMenu,
    Loader
  },
  data: () => {
    return {
      loader: false,
      list: [],
      count: null,
      search: "",
      page: 1,
      category: {
        attributes: {
          name: "",
          description: ""
        }
      },
      allowInfinite: true,
      showPreloader: false,
      infiniteScrollCount: 0
    };
  },
  watch: {
    search: debounce(function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchListWorkouts(this.search, this.idCategory, 1);
      }
    }, 1000)
  },
  computed: {
    existMoreLoadItem() {
      return this.list.length < this.count;
    }
  },
  mounted() {
    this.$store.dispatch("apptreningpilkarski/setActualPage", 'plans-training');
    this.fetchCategoryWorkout(this.idCategory);
    this.fetchListWorkouts(this.search, this.idCategory, this.page);
  },
  methods: {
    showAccessItem(access) {
      if (this.getDeviceIos && !access) {
        return false
      } else {
        return true
      }
    },
    async fetchCategoryWorkout(id) {
      try {
        const response = await WorkoutsService.categoryWorkout(id);
        this.category = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchListWorkouts(search, category, page) {
      this.loader = true;
      try {
        const response = await WorkoutsService.listWorkouts(
          search,
          category,
          page
        );
        this.list = response.data.data;
        this.count = response.data.meta.totalItems;
        this.loader = false;
      } catch (error) {
        console.log(error);
      }
    },
    async loadMore() {
      this.infiniteScrollCount++;
      if (this.existMoreLoadItem && this.infiniteScrollCount == 1) {
        this.page = this.page + 1;
        try {
          const response = await WorkoutsService.listWorkouts(
            this.search,
            this.idCategory,
            this.page
          );
          this.list = [...this.list, ...response.data.data];
          this.showPreloader = this.existMoreLoadItem;
          this.infiniteScrollCount = 0;
        } catch (error) {
          console.log(error);
        }
      }
    },
    secToTime(duration) {
      return secToTime(duration);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/plansTraining.scss";
</style>