import Api from './index'
import {store} from '@/store'

export default {
    async user(id) {
        return Api().get(`users/${id}`, {
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async meVerify(code) {
        const json = {
            data: {
                attributes: code
            }
        }
        return Api().post(`me/verify`, json, {
            headers: {
                'Accept': 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json',
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async meResendVerificationCode() {
        return Api().post(`/me/resendVerificationCode`, {}, {
            headers: {
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
    async updateUser(id, data) {
        return Api().patch(`users/${id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
                'Authorization': `Bearer ${store.getters['apptreningpilkarski/myToken']}`,
            }
        })
    },
}