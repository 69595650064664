import { store } from '@/store'

//Auth
import LoginPage from '@/views/Auth/LoginPage'
import RegisterPage from '@/views/Auth/RegisterPage'
import ForgotPasswordPage from '@/views/Auth/ForgotPasswordPage'
import ForgotPasswordSuccessPage from '@/views/Auth/ForgotPasswordSuccessPage'
import PlanUpgradePage from '@/views/Auth/PlanUpgradePage';

//Page
import HomePage from '@/views/Pages/HomePage'
import AccountPage from '@/views/Pages/AccountPage'
import VerifiedAccountPage from '@/views/Pages/VerifiedAccountPage'
import VerifiedAccountSuccessPage from '@/views/Pages/VerifiedAccountSuccessPage'
import AboutPage from '@/views/Pages/AboutPage'
import AboutAppPage from '@/views/Pages/AboutAppPage'
import MoreMenu from '@/views/Pages/MoreMenu'
import NoAccess from '@/views/Pages/NoAccess';
import NotFoundPage from '@/views/Pages/NotFoundPage';
import SettingsPage from '@/views/Pages/SettingsPage';
import RegulationPage from '@/views/Pages/RegulationPage';
import PrivacyPolicyPage from '@/views/Pages/PrivacyPolicyPage';

//Exercises
import ListExercises from '@/views/Pages/Exercises/ListExercises';
import Exercise from '@/views/Pages/Exercises/Exercise';
import CategoriesExercises from '@/views/Pages/Exercises/CategoriesExercises';

//PlansTraining
import CategoriesPlansTraining from '@/views/Pages/PlansTraining/CategoriesPlansTraining';
import ListPlansTraining from '@/views/Pages/PlansTraining/ListPlansTraining';
import PlanTraining from '@/views/Pages/PlansTraining/PlanTraining';
import PlayerPlanTraining from '@/views/Pages/PlansTraining/PlayerPlanTraining';

function checkAuth(to, from, resolve, reject) {
  const router = this;
  resolve();
  router.navigate('/home/');
}

const routes = [
  {
    path: '/',
    beforeEnter: checkAuth,
  },
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/register/',
    component: RegisterPage,
  },
  {
    path: '/regulation/',
    component: RegulationPage,
  },
  {
    path: '/privacy-policy/',
    component: PrivacyPolicyPage,
  },
  {
    path: '/account/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['apptreningpilkarski/isAuthenticated']) {
        resolve({component: AccountPage});
      } else {
        reject();
        router.navigate('/no-access/');
      }
    }
  },
  {
    path: '/verified/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['apptreningpilkarski/isAuthenticated']) {
        resolve({component: VerifiedAccountPage});
      } else {
        reject();
        router.navigate('/no-access/');
      }
    }
  },
  {
    path: '/verified-success/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['apptreningpilkarski/isAuthenticated']) {
        resolve({component: VerifiedAccountSuccessPage});
      } else {
        reject();
        router.navigate('/no-access/');
      }
    }
  },
  {
    path: '/forgot-password/',
    component: ForgotPasswordPage,
  },
  {
    path: '/forgot-password-success/',
    component: ForgotPasswordSuccessPage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/about-app/',
    component: AboutAppPage,
  },
  {
    path: '/settings/',
    async: function(to, from, resolve, reject) {
      const router = this;
      
      if (store.getters['apptreningpilkarski/isAuthenticated']) {
        resolve({component: SettingsPage});
      } else {
        reject();
        router.navigate('/no-access/');
      }
    } 
  },
  {
    path: '/plan-upgrade/',
    component: PlanUpgradePage,
  },
  {
    path: '/categories-exercises/',
    component: CategoriesExercises,
  },
  {
    path: '/categories-exercises/:idCategory',
    component: ListExercises,
  },
  {
    path: '/categories-exercises/:idCategory/exercise/:idExercise',
    component: Exercise,
    props: true,
  },
  {
    path: '/categories-plans-training/',
    component: CategoriesPlansTraining,
  },
  {
    path: '/categories-plans-training/:idCategory',
    name: 'list-plans-training-categories',
    component: ListPlansTraining,
    props: true,
  },
  {
    path: '/categories-plans-training/:idCategory/plan/:idPlan',
    name: 'plan-training',
    component: PlanTraining,
    props: true,
  },
  {
    path: '/categories-plans-training/:idCategory/plan/:idPlan/player',
    name: 'player-plan-training',
    component: PlayerPlanTraining,
    props: true,
  },
  {
    path: '/more-menu/',
    component: MoreMenu,
  },
  {
    path: '/no-access/',
    component: NoAccess,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
